import * as Sentry from '@sentry/react';

import { createAsyncThunk } from '@reduxjs/toolkit';

export interface VerifyJobRequestData {
  zipCode: string;
  state: string;
  city: string;
  address: string;
  buyerId: string;
  jobNumber: string;
  name: string;
}

export interface ContructionMonitorPermitOption {
  jobAddress: string;
  jobCity: string;
  jobState: string;
  jobZipCode: string;
  owner: string;
  constructionMonitorPermitId: string;
  ownerAddress: string;
  ownerCity: string;
  ownerZipCode: string;
  ownerState: string;
  gcName: string;
  gcAddress: string;
  gcZipCode: string;
  gcState: string;
  gcCity: string;
  gcEmail: string;
  apn: string;
  jobType: 'commercial' | 'residential';
}

export const fetchQueuryJobSiteAddresses = createAsyncThunk<
  ContructionMonitorPermitOption[],
  VerifyJobRequestData
>('jobOwner/fetchAddresses', async (requestData: VerifyJobRequestData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/jobOwner/queryJobSiteAddress`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    const json: ContructionMonitorPermitOption[] = await response.json();
    return json
      .filter((permit) => permit !== null)
      .map((item: any) => ({
        jobAddress: item._source.flatfile.site_addrs,
        jobCity: item._source.flatfile.site_city,
        jobState: item._source.flatfile.site_state,
        jobZipCode: item._source.flatfile.site_zip,
        owner:
          item._source.flatfile.ownr_cmpny ||
          `${item._source.flatfile.ownr_first} ${item._source.flatfile.ownr_last}`,
        constructionMonitorPermitId: item._id,
        ownerAddress: item._source.flatfile.ownr_st,
        ownerCity: item._source.flatfile.ownr_city,
        ownerZipCode: item._source.flatfile.ownr_zip,
        ownerState: item._source.flatfile.ownr_state,
        gcName:
          item._source.flatfile.bldr_cmpny ||
          `${item._source.flatfile.bldr_first} ${item._source.flatfile.bldr_last}`.trim(),
        gcAddress: item._source.flatfile.bldr_st,
        gcZipCode: item._source.flatfile.bldr_zip,
        gcState: item._source.flatfile.bldr_state,
        gcCity: item._source.flatfile.bldr_city,
        gcEmail: item._source.flatfile.bldr_email,
        apn: item._source.parcel,
        jobType: item._source.flatfile.pmt_class.toLowerCase() || '',
      }));
  } catch (error) {
    console.error('Error fetching data: ', error);
    Sentry.captureException(error);
    return [];
  }
});
