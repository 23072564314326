import React, { useEffect, useMemo, useState } from 'react';
import { AlertTriangle, Edit3 } from 'react-feather';
import {
  formatMoney,
  getLienDeadlineDate,
  getPreLienDeadlineDate,
  getStateAbbreviation,
  SupplierJobApiResponse,
  SupplierJobUpdate,
} from '@dill/dill-shared';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  ArrowsDownUp,
  SortDescending,
  SortAscending,
  CheckCircle,
  CircleWavyCheck,
  Warning,
  WarningCircle,
  CircleDashed,
  MegaphoneSimple,
} from 'phosphor-react';
import moment from 'moment';

import emptyListImg from '../../../../assets/images/emptyList.png';

import AppCustomDropdown from '../../../general/AppCustomDropdown/AppCustomDropdown';
import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';
import { AppButton } from '../../../general/AppButton/AppButton';
import AppCreateReleaseModal from '../../../general/AppCreateReleaseModal/AppCreateReleaseModal';
import CreateSignatureModal from '../../lienWaivers/supplier/CreateSignatureModal/CreateSignatureModal';
import AppEditFirstDeliveryDateModal from '../../../general/AppEditFirstDeliveryDateModal';
import { Ellipsis } from 'react-css-spinners';
import { AppDropDown } from '../../../general/AppDropDown/AppDropDown';
import { components, type ControlProps, type StylesConfig } from 'react-select';
import { FormOptionItem } from '../../../../utils/interfaces/generalInterfaces';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateSupplierJob } from '../../../../redux/services/supplierJobsService';
import { AppVirtualizedList } from '../../../general/AppVirtualizedList/AppVirtualizedList';
import { useWindowActualWidth } from '../../../../utils/domTools';
import { AppDeleteModal } from '../../../general/AppDeleteModal/AppDeleteModal';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { COLORS } from '../../../../utils/colors';
import { useNavigate } from 'react-router-dom';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';
import {
  selectSupplierJobs,
  unSelectSupplierJobs,
} from '../../../../redux/globalSlices/supplierJobsSlice';

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row gap-3 font-semibold">
        <span>{props.data.icon}</span>
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );
};

const CustomControl = ({ children, ...props }: { children: React.ReactNode } & ControlProps) => {
  const { selectProps } = props;

  const selectedProps = selectProps && (selectProps.value as Record<string, any>);
  let activeValue = '';

  if (selectedProps && selectedProps?.value) {
    activeValue = selectedProps.value;
  }

  const activeIcon =
    activeValue === 'done' ? <CheckCircle fontWeight={600} size={14} /> : <Warning size={14} />;

  return (
    <components.Control {...props}>
      <span className="text-white font-semibold align-center">{activeIcon}</span>
      {children}
    </components.Control>
  );
};

const SupplierJobsTable = ({
  searchedSupplierJobs,
  infiniteRef,
  hasNextPage = false,
  loading = false,
  onSortChange = () => {},
  onReload = () => {},
  sortOptions,
  dataFullyLoaded,
  onJobUpdate = () => {},
}: {
  searchedSupplierJobs: SupplierJobApiResponse[];
  infiniteRef?: any;
  hasNextPage?: boolean;
  loading?: boolean;
  sortOptions: { sortOrder: 'asc' | 'desc'; sortBy: string };
  onSortChange: (props: { sortOrder: 'asc' | 'desc'; sortBy: string }) => void;
  onReload: () => void;
  dataFullyLoaded: boolean;
  onJobUpdate?: (job?: SupplierJobApiResponse) => void;
}) => {
  const dispatch = useAppDispatch();
  const { selectedSupplierJobs, supplierJobs } = useAppSelector((state) => state.supplierJobs);
  const [selectedSupplierJob, setSelectedSupplierJob] = useState({} as SupplierJobApiResponse);
  const [editJob, setEditJob] = useState<boolean>(false);
  const [editFirstDeliveryDate, setEditFirstDeliveryDate] = useState<boolean>(false);
  const [softFirstDeliveryDate, setSoftFirstDeliveryDate] = useState<Date | null>(null);
  const [openCreateReleaseModal, setOpenCreateReleaseModal] = useState<boolean>(false);
  const [openDropdown, setOpenDropDown] = useState('');
  const navigate = useNavigate();
  const [openLienDropdown, setOpenLienDropdown] = useState('');
  const [isCreateSignatureModalOpen, setIsCreateSignatureModalOpen] = useState(false);
  const [signature, setSignature] = useState('');
  const [dateModalType, setDateModalType] = useState<
    'firstDeliveryDate' | 'lastDeliveryDate' | 'noticeOfCompletionDate'
  >('firstDeliveryDate');
  const [confirmMarkAsActive, setConfirmMarkAsActive] = useState<boolean>(false);
  const [sort, setSort] = useState('');
  const [sorted, setSorted] = useState(false);
  const [sortedSupplierJobs, setSortedSupplierJobs] = useState<SupplierJobApiResponse[]>([]);
  const width = useWindowActualWidth();
  const columns = useMemo(() => {
    return [
      { id: 'select', name: '', width: 20, cellType: 'SELECT' },
      { id: 'supplierJob', name: 'Job', width: 130, cellType: 'VIEW_TEXT' },
      { id: 'customer', name: 'Customer', width: 110, cellType: 'VIEW_TEXT' },
      { id: 'fullAddress', name: 'Full Address', width: 110, cellType: 'VIEW_TEXT' },
      { id: 'owner', name: 'Owner', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'gc', name: 'GC', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'apn', name: 'Parcel Number', width: 110, cellType: 'VIEW_TEXT' },
      { id: 'firstDeliveryDate', name: 'First Furnishing Date', width: 130, cellType: 'VIEW_TEXT' },
      { id: 'lastDeliveryDate', name: 'Last Furnishing Date', width: 130, cellType: 'VIEW_TEXT' },
      {
        id: 'noticeOfCompletionDate',
        name: 'Notice of Completion Date',
        width: 150,
        cellType: 'VIEW_TEXT',
      },
      { id: 'prelien', name: 'Pre-Lien', width: 125, cellType: 'VIEW_TEXT' },
      { id: 'prelienDeadline', name: 'Pre-Lien Deadline', width: 135, cellType: 'VIEW_TEXT' },
      { id: 'lienDeadline', name: 'Lien Deadline', width: 125, cellType: 'VIEW_TEXT' },
      { id: 'openAR', name: 'Open AR', width: 115, cellType: 'VIEW_TEXT' },
      {
        id: 'estimatedMaterialAmount',
        name: 'Estimated Material Amount',
        width: 100,
        cellType: 'VIEW_TEXT',
      },
      { id: 'action', name: '', width: 220, cellType: 'VIEW_TEXT' },
    ];
  }, []);
  const { user } = useAppSelector((globalState) => globalState.auth);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  useEffect(() => {
    setSortedSupplierJobs(searchedSupplierJobs);
    setSort('');
    setSorted(false);
    if (selectedSupplierJob) {
      const updatedSupplierJob = searchedSupplierJobs.find(
        (job) => job.id === selectedSupplierJob.id
      );
      if (updatedSupplierJob) {
        setSelectedSupplierJob(updatedSupplierJob);
      }
    }
  }, [searchedSupplierJobs]);

  const handleAddSignature = (newSignature: string, isManual: boolean) => {
    setSignature(newSignature);
    setIsCreateSignatureModalOpen(false);
  };

  // Our timestamps are in UTC without timezone info. We need to convert them to PST
  const convertDateToPST = (date: Date) => {
    const dtString = moment.utc(date).format('YYYY-MM-DD');
    return new Date(`${dtString}T12:00:00`); // set to 12:00 PM
  };

  const getDeliveryDate = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | null | undefined
  ) => {
    let dt = null;

    if (invoiceDeliveryDate) {
      dt = convertDateToPST(invoiceDeliveryDate);
    } else if (softDeliveryDt) {
      dt = convertDateToPST(softDeliveryDt);
    }

    return dt;
  };

  const getDeliveryDateString = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | null | undefined
  ) => {
    const dt = getDeliveryDate(softDeliveryDt, invoiceDeliveryDate);

    return dt
      ? dt.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : '';
  };

  const customStyles: StylesConfig<FormOptionItem, false> = {
    control: (provided: any, selectProps: any) => {
      const backgroundColor =
        selectProps?.selectProps?.value?.value === 'done' ? '#32D583' : '#F04438';

      return {
        ...provided,
        borderRadius: '34px !important',
        minHeight: '42px',
        borderColor: 'none',
        marginBottom: '4px',
        backgroundColor: backgroundColor,
        fontWeight: 600,
        paddingLeft: '8px',
        textAlign: 'center',
        boxShadow: 'none',
      };
    },
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#fff',
      width: '24px',
      height: '24px',
      paddingLeft: '0px',
      alignItems: 'center',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#fff',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      color: '#fff',
      paddingLeft: '0px',
      paddingRight: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
      opacity: state.isSelected ? 0.5 : 1,
      cursor: 'pointer',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      paddingLeft: '0px',
    }),
  };

  const customSelectComps = {
    Option: CustomOption,
    Control: CustomControl,
  };

  const updateSupplierJobLienStatus = async (
    supplierJobId: string,
    supplierJobDetails: SupplierJobUpdate
  ) => {
    return dispatch(
      updateSupplierJob({
        supplierJobId: supplierJobId,
        supplierJobDetails: supplierJobDetails,
      })
    );
  };

  const handleMarkAsActive = async () => {
    const result = await dispatch(
      updateSupplierJob({
        supplierJobId: selectedSupplierJob.id ?? '',
        supplierJobDetails: { archivedState: ['SUPPLIER_ARCHIVED'] },
      })
    );
    if (result.type === 'supplierJobs/updateSupplierJob/fulfilled') {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'SUCCESS',
          title: 'Successfully marked job as inactive!',
          subTitle: `This job has been archived and is no longer on the active job list.`,
          onClose: () => {
            onReload();
          },
        })
      );
    } else {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Failed to mark the job as inactive',
          subTitle: `Contact customer support.`,
          onClose: () => {},
        })
      );
    }
  };

  const sortByProperty = (
    sortType: 'asc' | 'desc',
    sortKey: string,
    getter: (item: any) => any,
    compare?: (a: any, b: any) => number
  ) => {
    // Filter out undefined or null values
    const filteredSupplierJobs = searchedSupplierJobs.filter((supplierJob) => {
      const value = getter(supplierJob);
      return value !== undefined && value !== null && value !== '';
    });

    // Create a sorted copy of the filtered supplier jobs
    const sortedSupplierJobCopy = [...filteredSupplierJobs];

    sortedSupplierJobCopy.sort((a, b) => {
      const valueA = getter(a);
      const valueB = getter(b);

      // Use custom compare function if provided
      if (compare) {
        return compare(valueA, valueB) * (sortType === 'asc' ? 1 : -1);
      } else {
        // Default alphanumeric sorting
        return (
          valueA
            .toString()
            .localeCompare(valueB.toString(), undefined, { numeric: true, sensitivity: 'base' }) *
          (sortType === 'asc' ? 1 : -1)
        );
      }
    });

    // Append items with undefined or null values to the sorted list
    const undefinedOrNullSupplierJob = searchedSupplierJobs.filter((supplierJob) => {
      const value = getter(supplierJob);
      return value === undefined || value === null || value === '';
    });

    const sortedSupplierJob = sortedSupplierJobCopy.concat(undefinedOrNullSupplierJob);

    setSortedSupplierJobs(sortedSupplierJob);
  };

  const handleSort = (value: string) => {
    if (searchedSupplierJobs) {
      if (sort === value) {
        if (sorted) {
          setSortedSupplierJobs(searchedSupplierJobs);
          setSort('');
          setSorted(false);
        } else {
          if (value === 'supplierJob') {
            sortByProperty('desc', 'name', (supplierJob) => supplierJob?.name);
          } else if (value === 'customer') {
            sortByProperty('desc', value, (supplierJob) => supplierJob?.buyer?.name);
          } else if (value === 'fullAddress') {
            sortByProperty('desc', value, (supplierJob) =>
              `${supplierJob?.jobAddress || ''}`.trim()
            );
          } else if (value === 'owner') {
            sortByProperty('desc', value, (supplierJob) => supplierJob?.owner);
          } else if (value === 'gc') {
            sortByProperty('desc', value, (supplierJob) => supplierJob?.GC);
          } else if (value === 'apn') {
            sortByProperty('desc', value, (supplierJob) => supplierJob?.apn);
          } else if (value === 'firstDeliveryDate') {
            sortByProperty(
              'desc',
              value,
              (supplierJob) => {
                const deliveryDate = supplierJob.firstDeliveryDate
                  ? supplierJob.firstDeliveryDate
                  : supplierJob.firstInvoiceDate;
                return deliveryDate ? new Date(deliveryDate).getTime() : null;
              },
              (a, b) => a - b
            );
          } else if (value === 'lastDeliveryDate') {
            sortByProperty(
              'desc',
              value,
              (supplierJob) => {
                const lastDeliveryDate = supplierJob?.lastDeliveryDate
                  ? supplierJob.lastDeliveryDate
                  : supplierJob.lastInvoiceDate;
                return lastDeliveryDate ? new Date(lastDeliveryDate).getTime() : null;
              },
              (a, b) => a - b
            );
          } else if (value === 'noticeOfCompletionDate') {
            sortByProperty(
              'desc',
              value,
              (supplierJob) => {
                return supplierJob.noticeOfCompletionDate
                  ? new Date(supplierJob.noticeOfCompletionDate).getTime()
                  : null;
              },
              (a, b) => a - b
            );
          } else if (value === 'prelien') {
            sortByProperty('desc', value, (supplierJob) =>
              supplierJob.preLienStatus
                ? supplierJob.preLienStatus
                : supplierJob.preLienNotice
                ? 'done'
                : 'not-yet'
            );
          } else if (value === 'prelienDeadline') {
            sortByProperty(
              'desc',
              value,
              (supplierJob) => {
                const firstDeliveryDate = getDeliveryDate(
                  supplierJob.firstInvoiceDate,
                  supplierJob.firstDeliveryDate
                );
                const processedNoticeOfCompletionDate =
                  supplierJob.noticeOfCompletionDate &&
                  typeof supplierJob.noticeOfCompletionDate === 'string'
                    ? new Date(supplierJob.noticeOfCompletionDate)
                    : supplierJob.noticeOfCompletionDate;
                const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
                const state =
                  supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
                    ? 'CA'
                    : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
                    ? 'CA'
                    : supplierJobState;
                const preLienDateDeadlineDate = getPreLienDeadlineDate(state, firstDeliveryDate);
                return preLienDateDeadlineDate ? preLienDateDeadlineDate.getTime() : null;
              },
              (a, b) => a - b
            );
          } else if (value === 'lienDeadline') {
            sortByProperty(
              'desc',
              value,
              (supplierJob) => {
                const lastDeliveryDate = getDeliveryDate(
                  supplierJob.lastInvoiceDate,
                  supplierJob.lastDeliveryDate
                );
                const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
                const state =
                  supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
                    ? 'CA'
                    : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
                    ? 'CA'
                    : supplierJobState;
                const lienDeadlineDate = getLienDeadlineDate(
                  state,
                  lastDeliveryDate,
                  supplierJob.noticeOfCompletionDate
                );
                return lienDeadlineDate ? lienDeadlineDate.getTime() : null;
              },
              (a, b) => a - b
            );
          } else if (value === 'openAR') {
            sortByProperty(
              'desc',
              value,
              (supplierJob) =>
                supplierJob?.invoices?.reduce((acc: any, invoice: any) => {
                  return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
                }, 0) || null,
              (a, b) => a - b
            );
          } else if (value === 'estimatedMaterialAmount') {
            sortByProperty(
              'desc',
              value,
              (supplierJob) => supplierJob?.estimatedMaterialAmount || null,
              (a, b) => a - b
            );
          }

          setSorted(true);
        }
      } else {
        if (value === 'supplierJob') {
          sortByProperty('asc', 'name', (supplierJob) => supplierJob?.name);
        } else if (value === 'customer') {
          sortByProperty('asc', value, (supplierJob) => supplierJob?.buyer?.name);
        } else if (value === 'fullAddress') {
          sortByProperty('asc', value, (supplierJob) => `${supplierJob?.jobAddress || ''}`.trim());
        } else if (value === 'owner') {
          sortByProperty('asc', value, (supplierJob) => supplierJob?.owner);
        } else if (value === 'gc') {
          sortByProperty('asc', value, (supplierJob) => supplierJob?.GC);
        } else if (value === 'apn') {
          sortByProperty('asc', value, (supplierJob) => supplierJob?.apn);
        } else if (value === 'firstDeliveryDate') {
          sortByProperty(
            'asc',
            value,
            (supplierJob) => {
              const deliveryDate = supplierJob.firstDeliveryDate
                ? supplierJob.firstDeliveryDate
                : supplierJob.firstInvoiceDate;
              return deliveryDate ? new Date(deliveryDate).getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (value === 'lastDeliveryDate') {
          sortByProperty(
            'asc',
            value,
            (supplierJob) => {
              const lastDeliveryDate = supplierJob.lastDeliveryDate
                ? supplierJob.lastDeliveryDate
                : supplierJob.lastInvoiceDate;
              return lastDeliveryDate ? new Date(lastDeliveryDate).getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (value === 'noticeOfCompletionDate') {
          sortByProperty(
            'asc',
            value,
            (supplierJob) => {
              return supplierJob.noticeOfCompletionDate
                ? new Date(supplierJob.noticeOfCompletionDate).getTime()
                : null;
            },
            (a, b) => a - b
          );
        } else if (value === 'prelien') {
          sortByProperty('asc', value, (supplierJob) =>
            supplierJob.preLienStatus
              ? supplierJob.preLienStatus
              : supplierJob.preLienNotice
              ? 'done'
              : 'not-yet'
          );
        } else if (value === 'prelienDeadline') {
          sortByProperty(
            'asc',
            value,
            (supplierJob) => {
              const firstDeliveryDate = getDeliveryDate(
                supplierJob.firstInvoiceDate,
                supplierJob.firstDeliveryDate
              );
              const processedNoticeOfCompletionDate =
                supplierJob.noticeOfCompletionDate &&
                typeof supplierJob.noticeOfCompletionDate === 'string'
                  ? new Date(supplierJob.noticeOfCompletionDate)
                  : supplierJob.noticeOfCompletionDate;
              const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
              const state =
                supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
                  ? 'CA'
                  : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
                  ? 'CA'
                  : supplierJobState;
              const preLienDateDeadlineDate = getPreLienDeadlineDate(state, firstDeliveryDate);
              return preLienDateDeadlineDate ? preLienDateDeadlineDate.getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (value === 'lienDeadline') {
          sortByProperty(
            'asc',
            value,
            (supplierJob) => {
              const lastDeliveryDate = getDeliveryDate(
                supplierJob.lastInvoiceDate,
                supplierJob.lastDeliveryDate
              );

              const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
              const state =
                supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
                  ? 'CA'
                  : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
                  ? 'CA'
                  : supplierJobState;
              const lienDeadlineDate = getLienDeadlineDate(
                state,
                lastDeliveryDate,
                supplierJob.noticeOfCompletionDate
              );
              return lienDeadlineDate ? lienDeadlineDate.getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (value === 'openAR') {
          sortByProperty(
            'asc',
            value,
            (supplierJob) =>
              supplierJob?.invoices?.reduce((acc: any, invoice: any) => {
                return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
              }, 0) || null,
            (a, b) => a - b
          );
        } else if (value === 'estimatedMaterialAmount') {
          sortByProperty(
            'asc',
            value,
            (supplierJob) => supplierJob?.estimatedMaterialAmount || null,
            (a, b) => a - b
          );
        }
        setSort(value);
        setSorted(false);
      }
    }
  };

  const renderRow = (item: { id: string; content: React.ReactNode }) => {
    const index = Number(item.id);
    const key = index + 'supplier-job';
    const supplierJob = sortedSupplierJobs[index];

    const firstDeliveryDate = getDeliveryDate(
      supplierJob.firstInvoiceDate,
      supplierJob.firstDeliveryDate
    );

    const lastDeliveryDate = getDeliveryDate(
      supplierJob.lastInvoiceDate,
      supplierJob.lastDeliveryDate
    );

    const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
    const state =
      supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
        ? 'CA'
        : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
        ? 'CA'
        : supplierJobState;
    const lienDateDeadlineDate = getLienDeadlineDate(
      state,
      lastDeliveryDate,
      supplierJob.noticeOfCompletionDate
    );
    const isLienDateDeadlinePassed = lienDateDeadlineDate
      ? moment().isAfter(lienDateDeadlineDate)
      : false;

    const lienDeadlineDateString = lienDateDeadlineDate
      ? lienDateDeadlineDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : '';

    const processedNoticeOfCompletionDate =
      supplierJob.noticeOfCompletionDate && typeof supplierJob.noticeOfCompletionDate === 'string'
        ? new Date(supplierJob.noticeOfCompletionDate)
        : supplierJob.noticeOfCompletionDate;
    const preLienDateDeadlineDate = getPreLienDeadlineDate(state, firstDeliveryDate);
    const isPreLienDateDeadlinePassed = preLienDateDeadlineDate
      ? moment().isAfter(preLienDateDeadlineDate)
      : false;
    const preLienDateDeadlineDateString =
      preLienDateDeadlineDate &&
      preLienDateDeadlineDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

    const finalPreLienStatus = supplierJob.preLienNotice ? 'done' : 'not-yet';

    const totalOpenAR =
      supplierJob?.invoices?.reduce((acc, invoice) => {
        return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
      }, 0) || 0; // Added initial value of 0

    return (
      <div key={supplierJob.id} className="w-full flex-row">
        <div
          className="w-full flex items-center justify-between px-4 hover:bg-slate-50 cursor-pointer"
          onClick={(e) => {
            if (e.defaultPrevented) return;
            navigate(`/supplierJobs/${supplierJob.id}`);
          }}>
          {columns.map((column) => {
            return (
              <div
                key={column.id + supplierJob.id}
                style={{ minWidth: 60, flex: column.width / totalWidth }}
                className={`flex flex-col`}>
                <div className="">
                  {column.cellType === 'SELECT' && column.id === 'select' && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className={`py-3 px-1`}>
                      <AppCheckBox
                        isChecked={selectedSupplierJobs.some((obj) => obj.id === supplierJob.id)}
                        onClick={(isChecked: boolean) => {
                          if (isChecked) {
                            dispatch(selectSupplierJobs([supplierJob]));
                          } else {
                            dispatch(unSelectSupplierJobs([supplierJob]));
                          }
                        }}
                      />
                    </div>
                  )}
                  {column.id === 'supplierJob' && (
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-col w-4/5">
                        <p
                          data-tooltip-id={`job-tooltip-${supplierJob.id}`}
                          className="text-sm capitalize font-bold app-line-clamp-2">
                          {supplierJob.name}
                        </p>
                        <ReactTooltip
                          id={`job-tooltip-${supplierJob.id}`}
                          place="top"
                          variant="dark"
                          opacity={'100%'}
                          style={{
                            background: '#222',
                            zIndex: 99999,
                          }}>
                          <div className="flex flex-col">
                            <span className="text-xs capitalize overflow-hidden text-ellipsis">
                              {supplierJob.jobNumber ? `${supplierJob.jobNumber} - ` : ''}
                            </span>
                            <span className="text-xs capitalize">{supplierJob.name || ''}</span>
                          </div>
                        </ReactTooltip>
                      </div>

                      {supplierJob.constructionMonitorPermitId && (
                        <>
                          <div className="flex flex-col items-center pt-1">
                            <CircleWavyCheck color="#0BA5EC" />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {column.id === 'customer' && (
                    <div className="w-full flex flex-col">
                      <p
                        data-tooltip-id={`buyer-tooltip-customer-${supplierJob.id}`}
                        className="text-sm capitalize app-line-clamp-2">
                        {`${supplierJob.buyer?.name ? `${supplierJob.buyer?.name} - ` : ''} ${
                          supplierJob.buyer?.address || ''
                        } ${supplierJob.buyer?.city ? `${supplierJob.buyer?.city}, ` : ''}${
                          supplierJob.buyer?.state || ''
                        } ${supplierJob.buyer?.zipCode || ''}`}
                      </p>
                      <ReactTooltip
                        id={`buyer-tooltip-customer-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">
                            {supplierJob.buyer?.name || ''}
                          </span>
                          <span className="text-xs capitalize overflow-hidden text-ellipsis">
                            {`${supplierJob.buyer?.address || ''}`}
                          </span>
                          <span className="text-xs capitalize overflow-hidden text-ellipsis">
                            {`${supplierJob.buyer?.city ? `${supplierJob.buyer?.city}, ` : ''}${
                              supplierJob.buyer?.state || ''
                            } ${supplierJob.buyer?.zipCode || ''}`}
                          </span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'fullAddress' && (
                    <div className="w-full flex flex-col">
                      <p
                        data-tooltip-id={`fullAddress-tooltip-${supplierJob.id}`}
                        className="text-sm capitalize app-line-clamp-2">
                        {`${supplierJob.jobAddress || ''} ${
                          supplierJob.jobCity ? `${supplierJob.jobCity}, ` : ''
                        }${supplierJob.jobState || ''} ${supplierJob.jobZipCode || ''}`}
                      </p>
                      <ReactTooltip
                        id={`fullAddress-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">{supplierJob.jobAddress || ''}</span>
                          <span className="text-xs capitalize">{`${
                            supplierJob.jobCity ? `${supplierJob.jobCity}, ` : ''
                          }${supplierJob.jobState || ''} ${supplierJob.jobZipCode || ''}`}</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'owner' && (
                    <div className="w-full flex flex-col">
                      <p
                        data-tooltip-id={`owner-tooltip-${supplierJob.id}`}
                        className="text-sm capitalize app-line-clamp-2">
                        {`${supplierJob.owner ? `${supplierJob.owner} - ` : ''} ${
                          supplierJob.ownerAddress || ''
                        } ${supplierJob.ownerCity ? `${supplierJob.ownerCity}, ` : ''}${
                          supplierJob.ownerState || ''
                        } ${supplierJob.ownerZipCode || ''}`}
                      </p>
                      <ReactTooltip
                        id={`owner-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">{supplierJob.owner}</span>
                          <span className="text-xs capitalize">
                            {supplierJob.ownerAddress || ''}
                          </span>
                          <span className="text-xs capitalize">{`${
                            supplierJob.ownerCity ? `${supplierJob.ownerCity}, ` : ''
                          }${supplierJob.ownerState || ''} ${
                            supplierJob.ownerZipCode || ''
                          }`}</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'gc' && (
                    <div className="w-full flex flex-col">
                      <p
                        data-tooltip-id={`gc-tooltip-${supplierJob.id}`}
                        className="text-sm capitalize app-line-clamp-2">
                        {`${supplierJob.GC ? `${supplierJob.GC} - ` : ''} ${
                          supplierJob.GCAddress || ''
                        } ${supplierJob?.GCCity ? `${supplierJob?.GCCity}, ` : ''}${
                          supplierJob?.GCState || ''
                        } ${supplierJob?.GCZipCode || ''}`}
                      </p>
                      <ReactTooltip
                        id={`gc-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">{supplierJob?.GC || ''}</span>
                          <span className="text-xs capitalize">{`${
                            supplierJob?.GCAddress || ''
                          }`}</span>
                          <span className="text-xs capitalize">{`${
                            supplierJob?.GCCity ? `${supplierJob?.GCCity}, ` : ''
                          }${supplierJob?.GCState || ''} ${supplierJob?.GCZipCode || ''}`}</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'apn' && (
                    <div className="w-full flex flex-col">
                      <p className="text-sm capitalize overflow-hidden text-ellipsis">
                        {supplierJob?.apn || ''}
                      </p>
                    </div>
                  )}
                  {column.id === 'firstDeliveryDate' && (
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row gap-1 items-center justify-end text-sm ">
                        {firstDeliveryDate && !supplierJob.firstDeliveryDate && (
                          <div
                            data-tooltip-id="soft-delivery-date-tooltip"
                            className="w-20px h-20px flex justify-center items-center rounded-full">
                            <AlertTriangle size={16} color="#FDB022" />
                          </div>
                        )}
                        {
                          <>
                            {getDeliveryDateString(
                              supplierJob.firstInvoiceDate,
                              supplierJob.firstDeliveryDate
                            ) || ''}
                          </>
                        }
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            const firstDeliveryDt = getDeliveryDate(
                              supplierJob.firstInvoiceDate,
                              supplierJob.firstDeliveryDate
                            );

                            if (firstDeliveryDt) {
                              setSoftFirstDeliveryDate(firstDeliveryDt);
                            }
                            setDateModalType('firstDeliveryDate');
                            setSelectedSupplierJob(supplierJob);
                            setEditFirstDeliveryDate(true);
                          }}>
                          <Edit3 size={16} />
                        </button>
                      </div>
                    </div>
                  )}
                  {column.id === 'lastDeliveryDate' && (
                    <div className="w-full flex flex-col mr-1">
                      <div className="flex flex-row gap-1 items-center justify-end text-sm mr-1">
                        {getDeliveryDate(
                          supplierJob.lastInvoiceDate,
                          supplierJob.lastDeliveryDate
                        ) &&
                          !supplierJob.lastDeliveryDate && (
                            <div
                              data-tooltip-id="soft-delivery-date-tooltip"
                              className="w-20px h-20px flex justify-center items-center rounded-full">
                              <AlertTriangle size={16} color="#FDB022" />
                            </div>
                          )}
                        {getDeliveryDateString(
                          supplierJob.lastInvoiceDate,
                          supplierJob.lastDeliveryDate
                        ) || ''}
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            const lastDeliveryDt = getDeliveryDate(
                              supplierJob.lastInvoiceDate,
                              supplierJob.lastDeliveryDate
                            );
                            if (lastDeliveryDt) {
                              setSoftFirstDeliveryDate(lastDeliveryDt);
                            }
                            setDateModalType('lastDeliveryDate');
                            setSelectedSupplierJob(supplierJob);
                            setEditFirstDeliveryDate(true);
                          }}>
                          <Edit3 size={16} />
                        </button>
                      </div>
                    </div>
                  )}
                  {column.id === 'noticeOfCompletionDate' && (
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row gap-1 items-center justify-end text-sm mr-1">
                        {processedNoticeOfCompletionDate &&
                          processedNoticeOfCompletionDate.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })}
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            if (supplierJob.noticeOfCompletionDate) {
                              setSoftFirstDeliveryDate(supplierJob.noticeOfCompletionDate);
                            }
                            setDateModalType('noticeOfCompletionDate');
                            setSelectedSupplierJob(supplierJob);
                            setEditFirstDeliveryDate(true);
                          }}>
                          <Edit3 size={16} />
                        </button>
                      </div>
                    </div>
                  )}
                  {column.id === 'prelien' && (
                    <div className="w-full flex flex-col max-w-[90px]">
                      {finalPreLienStatus === 'not-yet' ? (
                        <div className="flex ERROR_500-BG text-sm WHITE-CLR font-semibold px-2 py-1 rounded-full items-center">
                          <Warning size={16} color={COLORS.WHITE} />
                          <div className="ml-2">Not Yet</div>
                        </div>
                      ) : (
                        <div className="flex SUCCESS_500-BG text-sm WHITE-CLR font-semibold px-2 py-1 rounded-full items-center">
                          <CheckCircle size={16} color={COLORS.WHITE} />
                          <div className="ml-2">Done</div>
                        </div>
                      )}
                    </div>
                  )}
                  {column.id === 'prelienDeadline' && (
                    <div
                      className={` flex flex-col w-full text-sm ${
                        preLienDateDeadlineDate && isPreLienDateDeadlinePassed ? 'text-red-500' : ''
                      }`}>
                      <div className="flex flex-row align-center gap-1 font-semibold">
                        <div className="flex flex-col justify-center">
                          {preLienDateDeadlineDate && isPreLienDateDeadlinePassed ? (
                            <WarningCircle
                              data-tooltip-id={`prelienDeadline-tooltip-${supplierJob.id}`}
                              size={16}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        {preLienDateDeadlineDateString}
                      </div>
                      <ReactTooltip
                        id={`prelienDeadline-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">Overdue</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'lienDeadline' && (
                    <div
                      className={` flex flex-col w-full text-sm ${
                        lienDateDeadlineDate && isLienDateDeadlinePassed && totalOpenAR > 0
                          ? 'text-red-500'
                          : 'GREY_500'
                      }`}>
                      <div className="flex flex-row align-center gap-1 font-semibold">
                        <div className="flex flex-col justify-center">
                          {lienDateDeadlineDate && isLienDateDeadlinePassed && totalOpenAR > 0 ? (
                            <WarningCircle
                              data-tooltip-id={`lienDeadline-tooltip-${supplierJob.id}`}
                              size={16}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {lienDeadlineDateString}
                      </div>
                      <ReactTooltip
                        id={`lienDeadline-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">Overdue</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'openAR' && (
                    <div className=" flex flex-col w-full">
                      <div className="flex flex-row align-center gap-1 GREY_500 text-sm">
                        <div className="flex flex-col justify-center">
                          {totalOpenAR > 0 ? formatMoney(totalOpenAR, 2) : ''}
                        </div>
                      </div>
                    </div>
                  )}
                  {column.id === 'estimatedMaterialAmount' && (
                    <div className=" flex flex-col w-full">
                      <div className="flex flex-row align-center gap-1 GREY_500 text-sm">
                        <div className="flex flex-col justify-center">
                          {supplierJob?.estimatedMaterialAmount
                            ? formatMoney(supplierJob?.estimatedMaterialAmount, 2)
                            : ''}
                        </div>
                      </div>
                    </div>
                  )}
                  {column.id === 'action' && (
                    <div
                      className="flex w-[200px]"
                      onClick={(e) => {
                        e.preventDefault();
                      }}>
                      <AppCustomDropdown
                        inputFieldStyles={{ width: '35px', marginLeft: '4px' }}
                        onSelect={(val) => {
                          setSelectedSupplierJob(supplierJob);
                          if (val === 'edit') {
                            setEditJob(true);
                          }
                          if (val === 'mark-as-inactive') {
                            setConfirmMarkAsActive(true);
                          }
                          if (val === 'createPreliminaryNotice') {
                            navigate(`/preliminaryNotices/overview/addPreliminaryNotice`, {
                              state: { jobId: supplierJob.id },
                            });
                          }
                        }}
                        options={
                          supplierJob.archivedState?.includes('SUPPLIER_ARCHIVED')
                            ? [
                                {
                                  value: 'edit',
                                  label: `${
                                    user?.userSupplier?.ownerVerificationEnabled
                                      ? 'Edit & Verify'
                                      : 'Edit'
                                  }`,
                                  icon: <Edit3 size={16} />,
                                },
                                {
                                  value: 'createPreliminaryNotice',
                                  label: 'Create Preliminary Notice',
                                  icon: <MegaphoneSimple size={16} />,
                                },
                              ]
                            : [
                                {
                                  value: 'edit',
                                  label: `${
                                    user?.userSupplier?.ownerVerificationEnabled
                                      ? 'Edit & Verify'
                                      : 'Edit'
                                  }`,
                                  icon: <Edit3 size={16} />,
                                },
                                {
                                  value: 'mark-as-inactive',
                                  label: 'Mark as Inactive',
                                  icon: <CircleDashed size={16} />,
                                },
                                {
                                  value: 'createPreliminaryNotice',
                                  label: 'Create Preliminary Notice',
                                  icon: <MegaphoneSimple size={16} />,
                                },
                              ]
                        }
                        width="220px"
                        isDisabled={status === 'Inactive'}
                        open={openDropdown === supplierJob.id}
                        onOpen={() => setOpenDropDown(supplierJob.id)}
                        onClose={() => setOpenDropDown('')}
                      />
                      <AppButton
                        buttonStyles={{ width: '100%', marginLeft: '8px', height: '35px' }}
                        text="Create Lien Release"
                        type="PRIMARY"
                        onClick={() => {
                          setSelectedSupplierJob(supplierJob);
                          setOpenCreateReleaseModal(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {index === searchedSupplierJobs.length - 2 && hasNextPage && !loading && (
          <div ref={infiniteRef} className="w-full flex items-center justify-center ">
            <Ellipsis color="#3762FB" size={70} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full h-5/6 flex flex-col border rounded-lg overflow-x-scroll overflow-y-hidden mt-4">
      <AppDeleteModal
        open={confirmMarkAsActive}
        handleClose={() => setConfirmMarkAsActive(false)}
        title={`Are you sure you want to mark ${selectedSupplierJob?.name} as a inactive?`}
        subTitle="This action will archive the job. This action can't be undone"
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        onSecondButtonClick={() => {
          handleMarkAsActive();
          setConfirmMarkAsActive(false);
        }}
      />
      <AppNewSupplierJobModal
        open={editJob}
        editMode={true}
        selectedSupplierJob={selectedSupplierJob}
        handleClose={({ wasBuyerEdited }) => {
          if (wasBuyerEdited) {
            onReload();
          }
          setEditJob(false);
        }}
        onReload={() => {}}
        onJobUpdate={onJobUpdate}
      />
      <AppCreateReleaseModal
        open={openCreateReleaseModal}
        handleClose={() => {
          setOpenCreateReleaseModal(false);
          setSignature('');
        }}
        selectedJob={selectedSupplierJob}
        onSignSignature={() => {
          setIsCreateSignatureModalOpen(true);
        }}
        signature={signature}
        onEditSupplierJob={() => {
          setEditJob(true);
        }}
      />
      <CreateSignatureModal
        open={isCreateSignatureModalOpen}
        handleClose={() => {
          setIsCreateSignatureModalOpen(false);
        }}
        addSignature={handleAddSignature}
        onDownloadRealease={() => {}}
        defaultIsManualSign={false}
      />
      <AppEditFirstDeliveryDateModal
        open={editFirstDeliveryDate}
        handleClose={() => {
          setSoftFirstDeliveryDate(null);
          setEditFirstDeliveryDate(false);
        }}
        dateType={dateModalType}
        selectedSupplierJob={selectedSupplierJob}
        softDeliveryDate={softFirstDeliveryDate}
        onReload={() => {}}
      />
      <ReactTooltip
        id={'soft-delivery-date-tooltip'}
        place="right"
        variant="dark"
        opacity={'100%'}
        style={{
          background: '#222',
          width: '300px',
          zIndex: 99999,
        }}>
        <span>Please confirm delivery date. This is a best guess based on the invoice date.</span>
      </ReactTooltip>
      <div
        className="flex border-b justify-between sticky top-0 PRIMARY_50-BG z-1 px-5"
        style={{
          width: width <= 1810 ? 1810 : width,
        }}>
        {columns.map((column) => {
          return (
            <div
              key={column.id}
              style={{
                minWidth: 60,
                flex: column.width / (totalWidth <= 1810 ? 1810 : width),
                width: `${(column.width / totalWidth) * (width <= 1810 ? 1810 : width)}px`, // Adjusted width calculation
              }}
              onClick={() => {
                if (dataFullyLoaded && column.cellType === 'VIEW_TEXT' && column.id !== 'action') {
                  handleSort(column.id);
                }
              }}>
              {column.cellType === 'SELECT' && column.id === 'select' && dataFullyLoaded && (
                <div className="py-3">
                  <AppCheckBox
                    isChecked={
                      selectedSupplierJobs.length !== 0 &&
                      selectedSupplierJobs.length === searchedSupplierJobs.length
                    }
                    onClick={(isChecked: boolean) => {
                      if (isChecked) {
                        dispatch(selectSupplierJobs(searchedSupplierJobs));
                      } else {
                        dispatch(unSelectSupplierJobs(searchedSupplierJobs));
                      }
                    }}
                  />
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && (
                <div className={`py-3 flex ${column.id !== 'action' ? 'cursor-pointer' : ''}`}>
                  <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
                  {column.id !== 'action' && (
                    <div>
                      {dataFullyLoaded && (
                        <div>
                          {sort === column.id && sorted ? (
                            <SortAscending color={COLORS.PRIMARY_500} />
                          ) : sort === column.id && !sorted ? (
                            <SortDescending color={COLORS.PRIMARY_500} />
                          ) : (
                            <ArrowsDownUp color={COLORS.GREY_400} />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {supplierJobs.length > 0 ? (
        <div>
          <AppVirtualizedList
            itemsLength={sortedSupplierJobs.length}
            itemHeight={70}
            containerHeight={100}
            renderRow={renderRow}
            topOffset={285}
            className=""
            containerStyle={{
              paddingBottom: '150px',
              width: width <= 1810 ? '1810px' : `${width}px`,
            }}
          />
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col items-center">
            <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
            <p>No Supplier Jobs</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupplierJobsTable;
