import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import emptyListImg from '../../../../assets/images/emptyList.png';
import moment from 'moment';
import './InvoicesTable.scss';
import { AppButton } from '../../../general/AppButton/AppButton';
import {
  QuickbooksInvoice,
  InvoiceStatus,
  AppFile,
  Supplier,
  formatMoney,
  roundTo,
  QuickbooksCreditMemo,
  QuickbooksInvoiceSchema,
  QuickbooksCreditMemoSchema,
  DillInvoice,
} from '@dill/dill-shared';
import { getDocumentPdf, getInvoicePdf } from '../../../../redux/services/buyersService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  resetInvoiceBase64,
  selectInvoiceIdsForDownload,
  unSelectInvoiceIdsForDownload,
} from '../../../../redux/globalSlices/buyersSlice';
import { logEvent } from 'firebase/analytics';
import { myAnalytics } from '../../../../firebase/config';
import {
  downloadFile,
  firebaseFileUploadHelper,
  openDillToast,
  printFile,
  truncateText,
} from '../../../../utils/helpers';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';
import { AppPdfPreviewModal } from '../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import { Eye, Download } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { AppPdfListModal } from '../../../general/AppPdfListModal/AppPdfListModal';
import {
  resetSelectCreditMemo,
  selectCreditMemo,
  selectInvoice,
  unSelectCreditMemo,
  unSelectInvoice,
} from '../../../../redux/globalSlices/invoiceSlice';
import { getJobs } from '../../../../redux/services/jobsService';
import AppViewInvoiceWithoutPdfModal from '../../../general/AppViewInvoiceWithoutPdfModal/AppViewInvoiceWithoutPdfModal';
import { useParamMainBuyerId } from '../../../../utils/hooks/useParamMainBuyerId';
import { twMerge } from 'tailwind-merge';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  ArrowsDownUp,
  SortDescending,
  SortAscending,
  ChatCircleText,
  Printer,
} from 'phosphor-react';
import AppCustomDropdown from '../../../general/AppCustomDropdown/AppCustomDropdown';
// import { AppChatModal } from '../../../general/AppChatModal/AppChatModal';
import { sendMessagesInvoice } from '../../../../redux/services/invoicesService';
import AppNotesModal from '../../../general/AppNotesModal/AppNotesModal';
import { AppVirtualizedList } from '../../../general/AppVirtualizedList/AppVirtualizedList';
import { useWindowActualWidth } from '../../../../utils/domTools';

export const InvoicesTable = ({
  invoices,
  selectedTabId,
  onPaySingleInvoice,
  selectedInvoiceForPreview,
  selectedCreditMemoForPreview,
  accumulateAmount,
}: {
  invoices: (QuickbooksInvoice | QuickbooksCreditMemo)[];
  selectedTabId: InvoiceStatus;
  selectedInvoiceForPreview: QuickbooksInvoice | null | undefined;
  selectedCreditMemoForPreview: QuickbooksCreditMemo | null | undefined;
  onPaySingleInvoice: (invoice: QuickbooksInvoice) => void;
  accumulateAmount: number;
}) => {
  const { invoiceBase64, invoiceIdsForDownload, previewPdf } = useAppSelector(
    (state) => state.buyers
  );
  const { selectedBuyerSuppliers } = useAppSelector((state) => state.buyers);
  const { selectedCreditMemos, selectedSupplierForBuyer } = useAppSelector(
    (state) => state.invoice
  );
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const width = useWindowActualWidth();

  const [selectedMainBuyerId] = useParamMainBuyerId();

  const [currentSupplierDomainId, setCurrentSupplierDomainId] = useState('');
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [isViewInvoiceWithoutPdfModalOpen, setIsViewInvoiceWithoutPdfModalOpen] = useState(false);
  const [openPdfListModal, setOpenPdfListModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<QuickbooksInvoice | null>(null);
  const [selectedCreditMemo, setSelectedCreditMemo] = useState<QuickbooksCreditMemo | null>(null);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState('');
  const [previewMode, setPreviewMode] = useState(false);
  const [printMode, setPrintMode] = useState(false);
  const [downloadPdfList, setDownloadPdfList] = useState([] as any);
  const [previewPdfList, setPreviewPdfList] = useState([] as any);
  const [invoiceSigned, setInvoiceSigned] = useState(false);
  const [sort, setSort] = useState('');
  const [sorted, setSorted] = useState(false);
  const [sortedInvoices, setSortedInvoices] = useState<
    (QuickbooksInvoice | QuickbooksCreditMemo)[]
  >([]);
  const [openCustomDropdown, setOpenCustomDropDown] = useState('');
  const [openChatModal, setOpenChatModal] = useState(false);
  const [loadingNote, setLoadingNote] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [category, setCategory] = useState('');

  const isAnySignedInvoice = useMemo(() => {
    let check = false;
    if (invoices) {
      check = invoices.some((documentDetails) => {
        const invoiceCheck = QuickbooksInvoiceSchema.safeParse(documentDetails);
        const creditMemoCheck = QuickbooksCreditMemoSchema.safeParse(documentDetails);
        let invoice: QuickbooksInvoice | null = null;
        let creditMemo: QuickbooksCreditMemo | null = null;
        if (invoiceCheck.success) {
          invoice = invoiceCheck.data;
        }
        if (creditMemoCheck.success) {
          creditMemo = creditMemoCheck.data;
        }
        if (!creditMemo) {
          return false;
        }
        return invoice && invoice.dillInvoice?.receivedBy;
      });
    }
    return check;
  }, [invoices]);
  const columns = useMemo(() => {
    if (selectedTabId === 'PENDING_PAY') {
      const currentColumns = [
        { id: 'select', name: '', width: 50, cellType: 'SELECT' },
        { id: 'docNumber', name: 'Document #', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'type', name: 'Type', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'poNumber', name: 'PO #', width: 80, cellType: 'VIEW_TEXT' },
        { id: 'job', name: 'Job', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'dueDate', name: 'Due Date', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'createdAt', name: 'Date', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'terms', name: 'Terms', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'amount', name: 'Amount', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'balance', name: 'Open', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'payment', name: 'Payment', width: 120, cellType: 'VIEW_TEXT' },

        // { id: 'isPastDueDate', name: 'State', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'signedBy', name: 'Signed By', width: 250, cellType: 'VIEW_TEXT' },
        { id: 'download', name: '', width: 180, cellType: 'VIEW_TEXT' },
      ];
      return isAnySignedInvoice
        ? currentColumns
        : currentColumns.filter((col) => col.id !== 'signedBy');
    } else if (selectedTabId !== 'PAID') {
      const currentColumns = [
        { id: 'select', name: '', width: 50, cellType: 'SELECT' },
        { id: 'docNumber', name: 'Document #', width: 90, cellType: 'VIEW_TEXT' },
        { id: 'type', name: 'Type', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'poNumber', name: 'PO #', width: 80, cellType: 'VIEW_TEXT' },
        { id: 'job', name: 'Job', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'dueDate', name: 'Due Date', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'createdAt', name: 'Date', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'terms', name: 'Terms', width: 80, cellType: 'VIEW_TEXT' },
        { id: 'amount', name: 'Amount', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'balance', name: 'Open', width: 120, cellType: 'VIEW_TEXT' },

        // { id: 'isPastDueDate', name: 'State', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'signedBy', name: 'Signed By', width: 250, cellType: 'VIEW_TEXT' },
        { id: 'download', name: '', width: 180, cellType: 'VIEW_TEXT' },
      ];
      return isAnySignedInvoice
        ? currentColumns
        : currentColumns.filter((col) => col.id !== 'signedBy');
    } else {
      const currentColumns = [
        { id: 'select', name: '', width: 50, cellType: 'SELECT' },
        { id: 'docNumber', name: 'Document #', width: 90, cellType: 'VIEW_TEXT' },
        { id: 'type', name: 'Type', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'poNumber', name: 'PO #', width: 80, cellType: 'VIEW_TEXT' },
        { id: 'job', name: 'Job', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'createdAt', name: 'Date', width: 140, cellType: 'VIEW_TEXT' },
        { id: 'dueDate', name: 'Due Date', width: 140, cellType: 'VIEW_TEXT' },
        { id: 'terms', name: 'Terms', width: 140, cellType: 'VIEW_TEXT' },
        { id: 'amount', name: 'Amount', width: 140, cellType: 'VIEW_TEXT' },
        // { id: 'balance', name: 'Open', width: 150, cellType: 'VIEW_TEXT' },

        // { id: 'isPastDueDate', name: 'State', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'signedBy', name: 'Signed By', width: 250, cellType: 'VIEW_TEXT' },
        { id: 'download', name: '', width: 180, cellType: 'VIEW_TEXT' },
      ];
      return isAnySignedInvoice
        ? currentColumns
        : currentColumns.filter((col) => col.id !== 'signedBy');
    }
  }, [selectedTabId, isAnySignedInvoice]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  useEffect(() => {
    if (invoiceBase64) {
      window.open('data:application/pdf;base64, ' + invoiceBase64, '_blank');

      dispatch(resetInvoiceBase64());
    }
    return () => {};
  }, [invoiceBase64]);

  useEffect(() => {
    if (previewPdf && previewMode) {
      setInvoiceSigned(false);
      setPreviewPdfList([{ url: previewPdf, name: 'pdf', date: new Date() }]);
      setOpenPdfPreviewModal(true);
      logEvent(myAnalytics, 'buyer_preview_invoice', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    }
  }, [previewPdf]);

  useEffect(() => {
    if (location.pathname) {
      if (params.supplierDomainId) {
        setCurrentSupplierDomainId(params.supplierDomainId);
      }
    }

    return () => {};
  }, [location.pathname]);

  useEffect(() => {
    setSortedInvoices([...invoices]);
  }, [invoices]);
  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
    }

    return () => {};
  }, [selectedMainBuyerId]);
  const selectedSupplier = useMemo(() => {
    const newSupplier: Supplier | null = null;
    if (params.supplierDomainId) {
      const supplierDomainId = params.supplierDomainId;

      const supplier: Supplier | undefined = selectedBuyerSuppliers.find(
        (item: Supplier) => item.domainId === supplierDomainId
      );
      return supplier;
    }
    return newSupplier;
  }, [location.pathname, selectedBuyerSuppliers]);

  const handleDownloadPdf = async ({
    type,
    id,
    docNumber,
    signedPdfs,
  }: {
    type: 'INVOICE' | 'CREDIT_MEMO';
    id: string;
    docNumber: string;
    signedPdfs?: AppFile[] | undefined | null;
  }) => {
    setPreviewMode(false);
    setPrintMode(false);
    setSelectedInvoiceNumber(docNumber);
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        console.log(a, b);
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      if (sortedPdfs.length > 1) {
        setDownloadPdfList(sortedPdfs);
        setOpenPdfListModal(true);
      } else {
        downloadFile(sortedPdfs[0].url || '', `invoice-${docNumber}-${+new Date()}.pdf`);
        logEvent(myAnalytics, 'buyer_download_invoice', {
          userId: user ? user.id : '',
          time: new Date(),
          route: location.pathname,
        });
      }
    } else {
      const res = await dispatch(
        getDocumentPdf({
          supplierDomainId: currentSupplierDomainId,
          id,
          type,
        })
      );
      if (res && res.type === 'buyers/getDocumentPdf/fulfilled') {
        openDillToast({ message: 'invoice pdf successfully downloaded', type: 'SUCCESS' });
      } else {
        openDillToast({ message: 'Failed to download invoice pdf', type: 'ERROR' });
      }
      logEvent(myAnalytics, 'buyer_download_invoice', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    }
  };

  const handlePrintPdf = async ({
    type,
    id,
    docNumber,
    signedPdfs,
  }: {
    type: 'INVOICE' | 'CREDIT_MEMO';
    id: string;
    docNumber: string;
    signedPdfs?: AppFile[] | undefined | null;
  }) => {
    setPreviewMode(false);
    setPrintMode(true);
    setSelectedInvoiceNumber(docNumber);
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        console.log(a, b);
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      if (sortedPdfs.length > 1) {
        setDownloadPdfList(sortedPdfs);
        setOpenPdfListModal(true);
      } else {
        printFile(sortedPdfs[0].url || '', `invoice-${docNumber}-${+new Date()}.pdf`);
        logEvent(myAnalytics, 'buyer_print_invoice', {
          userId: user ? user.id : '',
          time: new Date(),
          route: location.pathname,
        });
      }
    } else {
      const res = await dispatch(
        getDocumentPdf({
          supplierDomainId: currentSupplierDomainId,
          id,
          type,
          print: true,
        })
      );
      if (res && res.type !== 'buyers/getDocumentPdf/fulfilled') {
        openDillToast({ message: 'Error printing the invoice pdf', type: 'ERROR' });
      }
      logEvent(myAnalytics, 'buyer_print_invoice', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    }
  };

  const handlePreviewPdf = async ({
    type,
    id,
    docNumber,
    signedPdfs,
  }: {
    type: 'INVOICE' | 'CREDIT_MEMO';
    id: string;
    docNumber: string;
    signedPdfs?: AppFile[] | undefined | null;
  }) => {
    if (type === 'INVOICE') {
      setSelectedInvoiceNumber(docNumber);
    }
    setPreviewMode(true);
    setPrintMode(false);
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        console.log(a, b);
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      setInvoiceSigned(true);
      setPreviewPdfList(sortedPdfs);
      setOpenPdfPreviewModal(true);
      logEvent(myAnalytics, 'buyer_preview_invoice', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    } else {
      const res = await dispatch(
        getDocumentPdf({
          supplierDomainId: currentSupplierDomainId,
          id,
          type,
          preview: true,
        })
      );
      console.log({ res });

      if (res && res.type !== 'buyers/getDocumentPdf/fulfilled') {
        openDillToast({ message: 'Failed to download invoice pdf', type: 'ERROR' });
        if (selectedSupplier) {
          navigate(`/companies/${selectedMainBuyerId}/invoices/${selectedSupplier.domainId}`);
        }
      }
    }
  };

  const handlePreviewInvoiceWithoutPdf = (invoice: QuickbooksInvoice) => {
    setSelectedInvoice(invoice);
    setIsViewInvoiceWithoutPdfModalOpen(true);
  };

  const handleDownloadSelectedPdf = (url: string) => {
    if (printMode) {
      printFile(url, `invoice-${selectedInvoiceNumber}-${+new Date()}.pdf`);
    } else {
      downloadFile(url, `invoice-${selectedInvoiceNumber}-${+new Date()}.pdf`);
    }
  };

  const sortByProperty = (
    sortType: 'asc' | 'desc',
    sortKey: string,
    getter: (item: any) => any,
    compare?: (a: any, b: any) => number
  ) => {
    const filteredInvoices = invoices.filter((invoice) => {
      const value = getter(invoice);
      return value !== undefined && value !== null;
    });

    const sortedInvoicesCopy = [...filteredInvoices];
    sortedInvoicesCopy.sort((a, b) => {
      const valueA = getter(a);
      const valueB = getter(b);

      if (compare) {
        return compare(valueA, valueB) * (sortType === 'asc' ? 1 : -1);
      } else {
        const stringA = String(valueA).toUpperCase();
        const stringB = String(valueB).toUpperCase();

        if (stringA < stringB) {
          return sortType === 'asc' ? -1 : 1;
        } else if (stringA > stringB) {
          return sortType === 'asc' ? 1 : -1;
        }

        return 0;
      }
    });

    const undefinedOrNullInvoices = invoices.filter((invoice) => {
      const value = getter(invoice);
      return value === undefined || value === null;
    });

    const fnalInvoice = sortedInvoicesCopy.concat(undefinedOrNullInvoices);

    setSortedInvoices(fnalInvoice);
  };

  const handleSort = (value: string) => {
    if (invoices) {
      if (sort === value) {
        if (sorted) {
          setSortedInvoices(invoices);
          setSort('');
          setSorted(false);
        } else {
          if (value === 'docNumber') {
            sortByProperty('desc', value, (invoice) => invoice?.docNumber);
          } else if (value === 'poNumber') {
            sortByProperty('desc', value, (invoice) => invoice.poNumber);
          } else if (value === 'createdAt') {
            sortByProperty('desc', value, (invoice) => invoice.createdAt);
          } else if (value === 'dueDate') {
            sortByProperty('desc', value, (invoice) => invoice.dueDate);
          } else if (value === 'terms') {
            sortByProperty('desc', value, (invoice) => invoice.terms);
          } else if (value === 'amount') {
            sortByProperty(
              'desc',
              value,
              (invoice) => invoice.amount,
              (a, b) => a - b
            );
          } else if (value === 'job') {
            sortByProperty('desc', value, (invoice) => invoice?.quickBooksJob?.jobName);
          } else if (value === 'type') {
            sortByProperty('desc', value, (invoice) => invoice?.recordType?.toLowerCase());
          } else if (value === 'balance') {
            sortByProperty(
              'desc',
              value,
              (invoice) => invoice.balance,
              (a, b) => a - b
            );
          }

          setSorted(true);
        }
      } else {
        if (value === 'docNumber') {
          sortByProperty('asc', value, (invoice) => invoice?.docNumber);
        } else if (value === 'poNumber') {
          sortByProperty('asc', value, (invoice) => invoice.poNumber);
        } else if (value === 'createdAt') {
          sortByProperty('asc', value, (invoice) => invoice.createdAt);
        } else if (value === 'dueDate') {
          sortByProperty('asc', value, (invoice) => invoice.dueDate);
        } else if (value === 'terms') {
          sortByProperty('asc', value, (invoice) => invoice.terms);
        } else if (value === 'amount') {
          sortByProperty(
            'asc',
            value,
            (invoice) => invoice.amount,
            (a, b) => a - b
          );
        } else if (value === 'job') {
          sortByProperty('asc', value, (invoice) => invoice?.quickBooksJob?.jobName);
        } else if (value === 'type') {
          sortByProperty('asc', value, (invoice) => invoice?.recordType?.toLowerCase());
        } else if (value === 'balance') {
          sortByProperty(
            'asc',
            value,
            (invoice) => invoice.balance,
            (a, b) => a - b
          );
        }

        setSort(value);
        setSorted(false);
      }
    }
  };
  const getDocumentType = (
    invoice: QuickbooksInvoice | null,
    creditMemo: QuickbooksCreditMemo | null
  ): string => {
    if (invoice && invoice.recordType === 'FINANCE_CHARGE') {
      return 'Finance Charge';
    } else if (invoice && invoice.recordType === 'DEBIT_MEMO') {
      return 'Debit Memo';
    } else if (invoice && !invoice.recordType) {
      return 'Invoice';
    } else if (creditMemo && creditMemo.recordType === 'PAYMENT') {
      return 'Payment';
    } else if (creditMemo && creditMemo.recordType === 'MISC_CREDITS') {
      return 'Misc Credits';
    } else if (creditMemo) {
      return 'Credit Memo';
    } else {
      return '';
    }
  };

  const handleSendMessage = async (message: string) => {
    try {
      if (selectedInvoice) {
        setLoadingNote(true);
        const attachedFiles: { name: string; url: string; fileType: string }[] = [];
        for (let index = 0; index < fileList.length; index++) {
          const selectedFile = fileList[index];
          const blobUrl = URL.createObjectURL(selectedFile);
          const fileName = `${new Date().getTime()}-${selectedFile.name}`;
          const downloadUrl = await firebaseFileUploadHelper({
            blobUrl: blobUrl,
            fileNameWithExtension: `${fileName}`,
            storagePath: `invoices/sendMessage/attachments/${selectedMainBuyerId}/${selectedInvoice.id}`,
          });
          attachedFiles.push({
            url: downloadUrl ?? '',
            name: `${fileName}`,
            fileType: selectedFile.type,
          });
        }
        const result = await dispatch(
          sendMessagesInvoice({
            invoiceId: selectedInvoice.id,
            data: {
              message,
              date: new Date(),
              supplierId: selectedSupplier?.id ?? '',
              buyerId: selectedMainBuyerId,
              recepientId: selectedMainBuyerId,
              isRead: false,
              attachements: attachedFiles,
              category,
            },
          })
        );
        setLoadingNote(false);
        if (result.type === 'suppliers/sendMessagesInvoice/fulfilled') {
          setOpenChatModal(false);
          openDillToast({ message: 'Successfully sent the message.', type: 'SUCCESS' });
        } else {
          openDillToast({ message: 'Failed to send the message.', type: 'ERROR' });
        }
      }
    } catch (err: any) {
      openDillToast({ message: err.message || 'Failed to send the message.', type: 'ERROR' });
      console.log(err);
    }
  };

  const renderRow = (item: { id: string; content: React.ReactNode }) => {
    const index = Number(item.id);
    const key = index + 'buyer-invoice';
    const documentDetails: any = sortedInvoices[index];
    const invoiceCheck = QuickbooksInvoiceSchema.safeParse(documentDetails);
    const creditMemoCheck = QuickbooksCreditMemoSchema.safeParse(documentDetails);
    let invoice: QuickbooksInvoice | null = null;
    let creditMemo: QuickbooksCreditMemo | null = null;
    let id: string;
    let jobName: string;
    let jobAddress: string;
    let dillInvoice: DillInvoice | null = null;
    if (invoiceCheck.success) {
      invoice = invoiceCheck.data;
      id = invoice.id;
      if (invoice.jobId) {
        jobName = `${
          invoice.quickBooksJob?.jobNumber ? `${invoice.quickBooksJob?.jobNumber} - ` : ''
        }${invoice.quickBooksJob?.jobName ?? ''}`;
        jobAddress = invoice.quickBooksJob?.address || invoice.shipAddress?.address1 || '';
      }
      if (invoice.dillInvoice) {
        dillInvoice = invoice.dillInvoice;
      }
    }
    if (creditMemoCheck.success) {
      creditMemo = creditMemoCheck.data;
      id = creditMemo.id;
      if (creditMemo.jobId) {
        jobName = `${
          creditMemo.quickBooksJob?.jobNumber ? `${creditMemo.quickBooksJob?.jobNumber} - ` : ''
        }${creditMemo.quickBooksJob?.jobName ?? ''}`;
        jobAddress = creditMemo.quickBooksJob?.address || creditMemo.shipAddress?.address1 || '';
      }
    }
    if (!creditMemoCheck.success && !invoiceCheck.success) {
      if (documentDetails.remainingCredit) {
        console.log({
          documentDetails,
          creditMemoCheck,
          creditMemoCheckErrors: creditMemoCheck.error,
        });
      } else {
        console.log({
          documentDetails,
          invoiceCheck,
          invoiceCheckErrors: invoiceCheck.error,
        });
      }
    }

    return (
      <div key={key + 'oo'} className={'flex  px-5 my-2'}>
        {columns.map((column) => {
          let value: string | undefined;

          if (invoice) {
            type ObjectKey = keyof typeof invoice;
            const columnKey = column.id as ObjectKey;
            value = invoice[columnKey]?.toString();
          }
          if (creditMemo) {
            type ObjectKey = keyof typeof creditMemo;
            const columnKey = column.id as ObjectKey;
            value = creditMemo[columnKey]?.toString();
            if (column.id === 'amount') {
              value = `${creditMemo?.totalAmount}`;
            }
          }
          if (column.id === 'createdAt' && invoice) {
            value = invoice.createdAt ? moment(invoice.createdAt).utc().format('MM/DD/YYYY') : '';
          }
          if (column.id === 'createdAt' && creditMemo) {
            value = creditMemo.createdAt
              ? moment(creditMemo.createdAt).utc().format('MM/DD/YYYY')
              : '';
          }
          if (column.id === 'dueDate' && invoice) {
            value = invoice?.dueDate ? moment(invoice.dueDate).utc().format('MM/DD/YYYY') : '';
          }
          if (column.id === 'dueDate' && creditMemo) {
            value = '';
          }
          if (column.id === 'balance' || column.id === 'amount') {
            value = `$${roundTo(parseFloat(value || '0'), 2)}`;
          }
          if (column.id === 'payment' && invoice) {
            value = formatMoney(invoice?.dillInvoicePaymentSummary?.amountPaid || '0', 2);
          }
          if (column.id === 'type') {
            value = getDocumentType(invoice, creditMemo);
          }
          const options = [];
          if (invoice) {
            options.push({
              value: 'sendMessage',
              label: 'Send Message',
              icon: <ChatCircleText size={16} />,
            });
          }
          let isPdfAvailable = false;
          if ((invoice && invoice.isPdfAvailable) || (creditMemo && creditMemo.isPdfAvailable)) {
            isPdfAvailable = true;
            options.push({ value: 'print', label: 'Print', icon: <Printer size={16} /> });
          }
          if (isPdfAvailable && width <= 1480) {
            options.push({
              value: 'download',
              label: 'Download',
              icon: <Download size={16} />,
            });
          }
          if (isPdfAvailable && width <= 1380) {
            options.push({
              value: 'preview',
              label: 'Preview',
              icon: <Eye size={16} />,
            });
          }

          return (
            <div
              key={column.id}
              style={{ flex: column.width / totalWidth }}
              className="flex items-center w-full h-full  mx-0.5">
              {column.cellType === 'SELECT' && column.id === 'select' && invoice && (
                <AppCheckBox
                  isChecked={
                    invoiceIdsForDownload[selectedTabId] &&
                    invoiceIdsForDownload[selectedTabId].includes(id)
                  }
                  onClick={(isChecked: boolean) => {
                    if (!invoice) {
                      return;
                    }
                    if (isChecked) {
                      dispatch(
                        selectInvoiceIdsForDownload({
                          tabId: selectedTabId,
                          invoiceIds: [id],
                        })
                      );
                      if (selectedTabId !== 'PAID' && selectedTabId !== 'PENDING_PAY' && invoice) {
                        dispatch(selectInvoice({ tabId: selectedTabId, invoices: [invoice] }));
                      }
                    } else {
                      dispatch(
                        unSelectInvoiceIdsForDownload({
                          tabId: selectedTabId,
                          invoiceIds: [id],
                        })
                      );
                      if (selectedTabId !== 'PAID' && selectedTabId !== 'PENDING_PAY' && invoice) {
                        dispatch(unSelectInvoice({ tabId: selectedTabId, invoices: [invoice] }));
                      }
                    }
                  }}
                />
              )}
              {column.cellType === 'SELECT' &&
                column.id === 'select' &&
                creditMemo &&
                (((creditMemo?.recordType === 'CREDIT_MEMO' ||
                  creditMemo?.recordType === 'CREDIT_INVOICE') &&
                  selectedSupplierForBuyer?.integrationType === 'P21') ||
                  ((creditMemo?.recordType === 'CREDIT_MEMO' ||
                    creditMemo?.recordType === 'CREDIT_INVOICE') &&
                    selectedSupplierForBuyer?.integrationType === 'SAGE_100') ||
                  ((creditMemo?.recordType === 'CREDIT_MEMO' ||
                    creditMemo?.recordType === 'MISC_CREDITS') &&
                    selectedSupplierForBuyer?.integrationType === 'INFOR_DIST_SX') ||
                  ((creditMemo?.recordType === 'CREDIT_MEMO' ||
                    creditMemo?.recordType === 'CREDIT_INVOICE') &&
                    selectedSupplierForBuyer?.integrationType === 'DISTRIBUTION_ONE') ||
                  (creditMemo?.recordType === 'CREDIT_MEMO' &&
                    selectedSupplierForBuyer?.integrationType === 'MANUAL_UPLOAD') ||
                  ((creditMemo?.recordType === 'CREDIT_MEMO' ||
                    creditMemo?.recordType === 'CREDIT_INVOICE') &&
                    selectedSupplierForBuyer?.integrationType === 'ABLE_MANUAL_UPLOAD')) && (
                  <AppCheckBox
                    isChecked={
                      selectedCreditMemos[selectedTabId] &&
                      selectedCreditMemos[selectedTabId].some((itm) => itm.id === creditMemo?.id)
                    }
                    onClick={(isChecked: boolean) => {
                      if (!creditMemo) {
                        return;
                      }
                      if (isChecked) {
                        if (
                          selectedTabId !== 'PAID' &&
                          selectedTabId !== 'PENDING_PAY' &&
                          creditMemo
                        ) {
                          dispatch(
                            selectCreditMemo({ tabId: selectedTabId, creditMemos: [creditMemo] })
                          );
                        }
                      } else {
                        if (
                          selectedTabId !== 'PAID' &&
                          selectedTabId !== 'PENDING_PAY' &&
                          creditMemo
                        ) {
                          dispatch(
                            unSelectCreditMemo({
                              tabId: selectedTabId,
                              creditMemos: [creditMemo],
                            })
                          );
                        }
                      }
                    }}
                  />
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'download' &&
                column.id !== 'signedBy' &&
                column.id !== 'job' &&
                column.id !== 'isPastDueDate' && (
                  <p
                    className={
                      'text-sm ' +
                      (column.id === 'dueDate' &&
                      selectedTabId === 'OPEN' &&
                      invoice &&
                      invoice.status === 'PAST_DUE'
                        ? 'ERROR_500-CLR'
                        : 'TEXT_PRIMARY-CLR')
                    }>
                    {value}
                  </p>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id === 'job' &&
                documentDetails.quickBooksJob &&
                (jobName || jobAddress) && (
                  <div className={'text-sm w-full px-0.5'}>
                    <p className="font-bold text-xs text-center word-wrap">
                      {truncateText(`${jobName}`, 20)}
                    </p>
                    <p className="text-2xs  text-center word-wrap">
                      {truncateText(`${jobAddress}`, 20)}
                    </p>
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'job' &&
                column.id !== 'download' &&
                column.id !== 'isPastDueDate' &&
                column.id === 'signedBy' && (
                  <div className="items-center justify-center ">
                    {dillInvoice && dillInvoice.isReceived && (
                      <div className="items-center justify-center">
                        {dillInvoice.receivedBy && (
                          <p className={'text-xs font-bold text-center  ' + 'TEXT_PRIMARY-CLR'}>
                            {dillInvoice.receivedBy}
                          </p>
                        )}
                        {dillInvoice.receivedByTime && (
                          <p className={'text-xs text-center ' + 'TEXT_PRIMARY-CLR'}>
                            {moment(dillInvoice.receivedByTime).format('MM/DD  LT')}
                          </p>
                        )}
                      </div>
                    )}
                    {(!dillInvoice || (dillInvoice && !dillInvoice.isReceived)) && (
                      <p className={'text-sm  text-center ' + 'TEXT_PRIMARY-CLR'}>-</p>
                    )}
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'download' &&
                column.id === 'isPastDueDate' && (
                  <div
                    style={{ width: '100px' }}
                    className={
                      value === 'true'
                        ? 'rounded-lg text-sm SUCCESS_100-BG p-2 SUCCESS_600-CLR text-center'
                        : 'rounded-lg text-sm WARNING_100-BG p-2 WARNING_600-CLR text-center'
                    }>
                    {value === 'true' ? 'Past Due' : 'Current Due'}
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'download' && (
                <div className="flex justify-end w-full">
                  <>
                    {isPdfAvailable ? (
                      <AppCustomDropdown
                        inputFieldStyles={{ width: '35px', marginRight: '4px', marginTop: '5px' }}
                        options={options}
                        onSelect={(val) => {
                          if (invoice) {
                            setSelectedInvoice(invoice);
                            if (val === 'print' && invoice) {
                              handlePrintPdf({
                                type: 'INVOICE',
                                id: invoice.id,
                                docNumber: invoice.docNumber,
                                signedPdfs: invoice.dillInvoice?.signedPdfs,
                              });
                            }
                            if (val === 'download') {
                              handleDownloadPdf({
                                type: 'INVOICE',
                                id: invoice.id,
                                docNumber: invoice.docNumber,
                                signedPdfs: invoice.dillInvoice?.signedPdfs,
                              });
                            }
                            if (val === 'preview' && selectedSupplier) {
                              navigate(
                                `/companies/${selectedMainBuyerId}/invoices/${selectedSupplier.domainId}?invoiceId=${invoice.id}`
                              );
                              if (invoice.isPdfAvailable) {
                                handlePreviewPdf({
                                  id: invoice.id,
                                  type: 'INVOICE',
                                  docNumber: invoice.docNumber,
                                  signedPdfs: invoice.dillInvoice?.signedPdfs,
                                });
                              } else {
                                handlePreviewInvoiceWithoutPdf(invoice);
                              }
                            }
                          }
                          if (creditMemo) {
                            setSelectedCreditMemo(creditMemo);

                            if (val === 'print') {
                              handlePrintPdf({
                                type: 'CREDIT_MEMO',
                                id: creditMemo.id,
                                docNumber: creditMemo.docNumber,
                              });
                            }
                            if (val === 'download') {
                              handleDownloadPdf({
                                id: creditMemo.id,
                                docNumber: creditMemo.docNumber,
                                type: 'CREDIT_MEMO',
                              });
                            }
                            if (val === 'preview' && selectedSupplier) {
                              navigate(
                                `/companies/${selectedMainBuyerId}/invoices/${selectedSupplier.domainId}?creditMemoId=${creditMemo.id}`
                              );
                              if (creditMemo.isPdfAvailable) {
                                handlePreviewPdf({
                                  id: creditMemo.id,
                                  type: 'CREDIT_MEMO',
                                  docNumber: creditMemo.docNumber,
                                });
                              }
                            }
                          }

                          if (val === 'sendMessage') {
                            setOpenChatModal(true);
                          }
                        }}
                        open={
                          (invoice && openCustomDropdown === invoice.id) ||
                          (creditMemo && openCustomDropdown === creditMemo.id) ||
                          false
                        }
                        onOpen={() => setOpenCustomDropDown(invoice?.id ?? creditMemo?.id ?? '')}
                        onClose={() => setOpenCustomDropDown('')}
                        width={'150px'}
                      />
                    ) : (
                      <div></div>
                    )}
                    {isPdfAvailable && width > 1380 ? (
                      <AppButton
                        type="TERTIARY"
                        buttonStyles={{
                          padding: '8px',
                          margin: '5px 4px 5px 0px',
                          height: '36px',
                        }}
                        icon={<Eye color={COLORS.GREY_500} size={20} />}
                        onClick={() => {
                          if (selectedSupplier && invoice) {
                            setSelectedInvoice(invoice);
                            navigate(
                              `/companies/${selectedMainBuyerId}/invoices/${selectedSupplier.domainId}?invoiceId=${invoice.id}`
                            );
                            if (invoice.isPdfAvailable) {
                              handlePreviewPdf({
                                id: invoice.id,
                                type: 'INVOICE',
                                docNumber: invoice.docNumber,
                                signedPdfs: invoice.dillInvoice?.signedPdfs,
                              });
                            } else {
                              handlePreviewInvoiceWithoutPdf(invoice);
                            }
                          }
                          if (selectedSupplier && creditMemo) {
                            setSelectedCreditMemo(creditMemo);
                            navigate(
                              `/companies/${selectedMainBuyerId}/invoices/${selectedSupplier.domainId}?creditMemoId=${creditMemo.id}`
                            );
                            if (creditMemo.isPdfAvailable) {
                              handlePreviewPdf({
                                id: creditMemo.id,
                                type: 'CREDIT_MEMO',
                                docNumber: creditMemo.docNumber,
                              });
                            }
                          }
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                    {isPdfAvailable && width > 1480 ? (
                      <AppButton
                        type="TERTIARY"
                        buttonStyles={{
                          padding: '8px',
                          margin: '5px 4px 5px 0px',
                          height: '36px',
                        }}
                        icon={<Download color={COLORS.GREY_500} size={20} />}
                        onClick={() => {
                          if (invoice) {
                            handleDownloadPdf({
                              id: invoice.id,
                              type: 'INVOICE',
                              docNumber: invoice.docNumber,
                              signedPdfs: invoice.dillInvoice?.signedPdfs,
                            });
                          }
                          if (creditMemo) {
                            handleDownloadPdf({
                              id: creditMemo.id,
                              docNumber: creditMemo.docNumber,
                              type: 'CREDIT_MEMO',
                            });
                          }
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </>
                  <div data-tooltip-id={'supplier-is-verified'}>
                    {(invoice &&
                      selectedTabId !== 'PAID' &&
                      selectedTabId !== 'PENDING_PAY' &&
                      selectedSupplier?.integrationType !== 'DISTRIBUTION_ONE') ||
                    (invoice &&
                      selectedTabId !== 'PAID' &&
                      selectedTabId !== 'PENDING_PAY' &&
                      selectedSupplier?.integrationType === 'DISTRIBUTION_ONE' &&
                      invoice?.recordType !== 'FINANCE_CHARGE') ? (
                      <AppButton
                        text="Pay"
                        onClick={() => {
                          if (invoice) {
                            onPaySingleInvoice(invoice);
                          }
                        }}
                        buttonStyles={{ marginTop: '5px', height: '36px' }}
                        isDisabled={!selectedSupplier?.isFullyVerified || !invoice ? true : false}
                      />
                    ) : (
                      <div className="h-[36px] w-[70px]"></div>
                    )}
                  </div>
                  {!selectedSupplier?.isFullyVerified && (
                    <ReactTooltip
                      id={'supplier-is-verified'}
                      place="right"
                      variant="dark"
                      opacity={'100%'}
                      style={{
                        background: '#222',
                        width: '300px',
                        zIndex: 99999,
                      }}>
                      <span>
                        Supplier verification in progress. Check back in a business day or two
                      </span>
                    </ReactTooltip>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (selectedSupplier && selectedInvoiceForPreview) {
      const invoice = selectedInvoiceForPreview;
      if (invoice) {
        if (invoice?.isPdfAvailable) {
          setSelectedInvoice(invoice);
          handlePreviewPdf({
            id: invoice.id,
            type: 'INVOICE',
            docNumber: invoice.docNumber,
            signedPdfs: invoice.dillInvoice?.signedPdfs,
          });
        } else {
          handlePreviewInvoiceWithoutPdf(selectedInvoiceForPreview);
        }
      }
    }
  }, [selectedInvoiceForPreview, selectedSupplier]);
  useEffect(() => {
    if (selectedSupplier && selectedCreditMemoForPreview) {
      const creditMemo = selectedCreditMemoForPreview;

      if (creditMemo) {
        if (creditMemo?.isPdfAvailable) {
          setSelectedCreditMemo(creditMemo);
          handlePreviewPdf({
            id: creditMemo.id,
            type: 'CREDIT_MEMO',
            docNumber: creditMemo.docNumber,
          });
        }
      }
    }
  }, [selectedCreditMemoForPreview, selectedSupplier]);

  const validInvoices = invoices.filter(
    (documentDetails) => QuickbooksInvoiceSchema.safeParse(documentDetails).success
  );

  return (
    <div className=" h-full overflow-y-scroll">
      <AppNotesModal
        open={openChatModal}
        handleClose={() => {
          setOpenChatModal(false);
        }}
        onSubmit={(value: string) => {
          handleSendMessage(value);
        }}
        title={'Send Message'}
        primaryButtonText={'Send'}
        loading={loadingNote}
        hasAttachment={true}
        onSelectFile={(data) => setFileList(data)}
        hasDropDown={true}
        dropDownOptions={{
          label: 'Category',
          options: [
            {
              label: 'Dispute',
              value: 'Dispute',
            },
            {
              label: 'Other',
              value: 'Other',
            },
          ],
        }}
        onSelectDropDown={(data) => setCategory(data)}
      />
      {/* <AppChatModal
        handleClose={() => setOpenChatModal(false)}
        open={openChatModal}
        onSend={handleSendMessage}
        invoice={selectedInvoice}
      /> */}
      {isViewInvoiceWithoutPdfModalOpen && (
        <AppViewInvoiceWithoutPdfModal
          open={isViewInvoiceWithoutPdfModalOpen}
          handleClose={() => {
            setIsViewInvoiceWithoutPdfModalOpen(false);
            navigate(location.pathname);
          }}
          selectedInvoice={selectedInvoice}
          selectedSupplier={selectedSupplier}
        />
      )}
      <div className="flex flex-col overflow-x-scroll h-full  w-full border rounded-lg">
        <div className="flex w-full h-full flex-col overflow-y-hidden ">
          <div className="flex border-b px-5 items-center w-full sticky top-0 WHITE-BG py-3 z-10">
            {columns.map((column) => {
              let isAllInvoicesSelected = false;
              if (
                invoiceIdsForDownload[selectedTabId] &&
                invoiceIdsForDownload[selectedTabId].length > 0
              ) {
                isAllInvoicesSelected =
                  invoiceIdsForDownload[selectedTabId].length === validInvoices.length;
              }

              return (
                <div
                  key={column.id}
                  style={{ flex: column.width / totalWidth }}
                  className={twMerge(
                    `flex items-center h-full w-full  mx-0.5`,
                    column.id === 'job' ? 'justify-center' : ''
                  )}>
                  {column.cellType === 'SELECT' && column.id === 'select' && (
                    <AppCheckBox
                      isChecked={isAllInvoicesSelected}
                      onClick={(isChecked: boolean) => {
                        if (isChecked) {
                          dispatch(
                            selectInvoiceIdsForDownload({
                              tabId: selectedTabId,
                              invoiceIds: invoices
                                .filter((documentDetails) => {
                                  const invoiceCheck =
                                    QuickbooksInvoiceSchema.safeParse(documentDetails);
                                  if (invoiceCheck.success) {
                                    return true;
                                  }
                                  return false;
                                })
                                .map((invoice) => invoice.id),
                            })
                          );
                          if (selectedTabId !== 'PAID' && selectedTabId !== 'PENDING_PAY') {
                            dispatch(
                              selectInvoice({
                                tabId: selectedTabId,
                                invoices: invoices
                                  .filter((documentDetails) => {
                                    const invoiceCheck =
                                      QuickbooksInvoiceSchema.safeParse(documentDetails);
                                    if (invoiceCheck.success) {
                                      return true;
                                    }
                                    return false;
                                  })
                                  .map((invoice) => QuickbooksInvoiceSchema.parse(invoice)),
                              })
                            );
                          }
                        } else {
                          dispatch(
                            unSelectInvoiceIdsForDownload({
                              tabId: selectedTabId,
                              invoiceIds: invoices
                                .filter((documentDetails) => {
                                  const invoiceCheck =
                                    QuickbooksInvoiceSchema.safeParse(documentDetails);
                                  if (invoiceCheck.success) {
                                    return true;
                                  }
                                  return false;
                                })
                                .map((invoice) => invoice.id),
                            })
                          );
                          if (selectedTabId !== 'PAID' && selectedTabId !== 'PENDING_PAY') {
                            dispatch(
                              unSelectInvoice({
                                tabId: selectedTabId,
                                invoices: invoices
                                  .filter((documentDetails) => {
                                    const invoiceCheck =
                                      QuickbooksInvoiceSchema.safeParse(documentDetails);
                                    if (invoiceCheck.success) {
                                      return true;
                                    }
                                    return false;
                                  })
                                  .map((invoice) => QuickbooksInvoiceSchema.parse(invoice)),
                              })
                            );
                          }
                        }
                      }}
                    />
                  )}
                  {column.cellType !== 'SELECT' && (
                    <div
                      onClick={() => handleSort(column.id)}
                      className="cursor-pointer flex align-center ">
                      <p className={twMerge('TEXT_SECONDARY-CLR text-xs word-wrap')}>
                        {column.name}
                      </p>
                      {column.id !== 'download' && (
                        <div>
                          {sort === column.id && sorted ? (
                            <SortAscending color={COLORS.GREY_400} />
                          ) : sort === column.id && !sorted ? (
                            <SortDescending color={COLORS.GREY_400} />
                          ) : (
                            <ArrowsDownUp color={COLORS.GREY_400} />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {invoices.length < 1 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center">
                <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                <p>No invoices</p>
              </div>
            </div>
          )}
          <div>
            {invoices.length > 0 && (
              <AppVirtualizedList
                itemsLength={sortedInvoices.length}
                itemHeight={70}
                containerHeight={100}
                renderRow={renderRow}
                topOffset={320}
                className=""
                containerStyle={{
                  paddingBottom: '150px',
                }}
              />
            )}
          </div>
        </div>
      </div>
      <AppPdfListModal
        open={openPdfListModal}
        handleClose={() => {
          setOpenPdfListModal(false);
        }}
        pdfList={downloadPdfList}
        invoice={selectedInvoiceNumber}
        handleDownloadPdf={handleDownloadSelectedPdf}
        buttonText={printMode ? 'Print' : 'Download'}
      />
      <AppPdfPreviewModal
        open={openPdfPreviewModal}
        handleClose={() => {
          setOpenPdfPreviewModal(false);
          navigate(location.pathname);
          setSelectedCreditMemo(null);
          setSelectedInvoice(null);
        }}
        invoice={
          selectedInvoice
            ? selectedInvoice?.docNumber || ''
            : selectedCreditMemo
            ? selectedCreditMemo?.docNumber || ''
            : ''
        }
        pdfType={selectedInvoice ? 'INVOICE' : selectedCreditMemo ? 'CREDIT_MEMO' : undefined}
        pdfList={previewPdfList}
        handleDownloadPdf={handleDownloadSelectedPdf}
        isSigned={invoiceSigned}
      />
    </div>
  );
};
