import { Modal } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X, ChevronDown, List } from 'react-feather';
import { CheckCircle, Timer, Warning, ClockClockwise, FileText } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import './AppPdfPreviewModal.scss';
import { pdfjs } from 'react-pdf';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { printPlugin } from '@react-pdf-viewer/print';
import moment from 'moment';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import { DillLinkedTransaction, DillTransaction, formatMoney } from '@dill/dill-shared';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import AppReleaseStatusPill from '../AppReleaseStatusPill/AppReleaseStatusPill';
import { useNavigate, useLocation } from 'react-router-dom';
import { InvoiceReference } from '../../../constants/invoice';
import AppInvoiceList from '../AppInvoiceList';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';

interface SignedPdf {
  name: string;
  url: string;
  date: Date;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const AppPdfPreviewModal = ({
  open,
  handleClose,
  invoice,
  pdfList,
  handleDownloadPdf,
  isSigned = true,
  pdfType = 'INVOICE',
  releaseDetails,
  mode = 'Supplier',
  showPendingMessage = true,
  onShowHistory,
  showHistoryButton = true,
}: {
  open: boolean;
  invoice: string;
  pdfList: SignedPdf[];
  pdfType?: 'INVOICE' | 'CREDIT_MEMO' | 'SALES_RECEIPT' | 'RELEASE' | 'PRELIMINARY_NOTICE';
  handleDownloadPdf: (url: string) => void;
  handleClose: () => void;
  isSigned?: boolean;
  releaseDetails?: {
    recipient: string;
    type: string;
    paymentAmount: number | null;
    throughDate?: string | Date | null;
    dueDate?: string | Date | null;
    job: string;
    status:
      | 'Declined'
      | 'Rejected'
      | 'Requested'
      | 'Signed'
      | 'Scheduled'
      | 'Pending'
      | 'Sent to GC';
    declineReason?: string | null;
    releaseAttachments?: { name: string; url: string }[] | null;
    notes?: string | null;
    signedTime?: Date | null;
    signedBy?: string | null;
    transactions?: DillTransaction[] | DillLinkedTransaction[] | null;
    sentToGCTime?: Date | null;
    sentToGCEmails?: string[] | null;
    invoiceList?: InvoiceReference[] | null;
  };
  mode?: 'Supplier' | 'Buyer';
  showPendingMessage?: boolean;
  onShowHistory?: () => void;
  showHistoryButton?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPdf, setSelectedPdf] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  const [selectedMainBuyerId] = useParamMainBuyerId();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pdfList.length > 0) {
      setSelectedPdf(pdfList[0].url);
    }
  }, [pdfList]);
  useEffect(() => {
    if (!open) {
      setShowMenu(false);
    }
  }, [open]);
  const onChangeValue = (event: any) => {
    setSelectedPdf(event.target.value);
  };
  const handleCloseModal = () => {
    setSelectedPdf('');
    handleClose();
  };
  const handleDownload = () => {
    if (selectedPdf) {
      handleDownloadPdf(selectedPdf);
    }
  };
  const zoomPluginInstance = zoomPlugin();
  const printPluginInstance = printPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { print } = printPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const handleShowDropdown = () => {
    setShowMenu(!showMenu);
  };
  const handleShowPayment = (val: string, buyerIdToFind: string) => {
    let paymentPath = '';
    if (location.pathname.startsWith(`/buyerLienReleases/${selectedMainBuyerId}/overview`)) {
      paymentPath = `/companies/${selectedMainBuyerId}/payments?transactionId=${val}`;
    } else {
      paymentPath = `/payments?transactionId=${val}`;
    }
    const viewPaymentTab = window.open(paymentPath, '_blank');
    if (viewPaymentTab) {
      viewPaymentTab.focus();
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="pdf-preview-modal-content">
        <div className="pdf-preview-title-container">
          <div className="pdf-preview-title-text">
            Preview{' '}
            {pdfType === 'SALES_RECEIPT'
              ? 'Sales Receipt'
              : pdfType === 'RELEASE'
              ? 'Release'
              : pdfType === 'PRELIMINARY_NOTICE'
              ? pdfList[0].name ?? 'Preliminary Notice'
              : 'Invoice'}
          </div>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleCloseModal}
          />
        </div>
        <div className="preview-content">
          {selectedPdf !== '' && (
            <div className="view-pdf-area">
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: '#eeeeee',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'space-around',
                  padding: '4px',
                }}>
                <CurrentPageLabel>
                  {(props: RenderCurrentPageLabelProps) => (
                    <span>{`page ${props.currentPage + 1} / ${props.numberOfPages}`}</span>
                  )}
                </CurrentPageLabel>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <ZoomOutButton />
                  <ZoomPopover />
                  <ZoomInButton />
                </div>
              </div>
              <Viewer
                fileUrl={selectedPdf}
                plugins={[zoomPluginInstance, pageNavigationPluginInstance, printPluginInstance]}
              />
            </div>
          )}
          {(pdfType === 'INVOICE' || pdfType === 'CREDIT_MEMO' || pdfType === 'SALES_RECEIPT') && (
            <div className="select-pdf-container">
              <div>
                <div className="select-pdf-title">
                  Select {pdfType === 'SALES_RECEIPT' ? 'a sales receipt' : 'an invoice'}
                </div>
                <div onChange={onChangeValue} className="preview-form-select-container">
                  {pdfList.map((item, i) => (
                    <label className="preview-pdf-tile-label" key={`${invoice}_${i}`}>
                      <input
                        type="radio"
                        name={item.name}
                        value={item.url}
                        checked={item.url === selectedPdf}
                        onChange={() => {}}
                      />
                      <div>
                        <div className="preview-tile-radio-button">
                          {item.url !== selectedPdf ? (
                            <div className="preview-tile-radio-icon" />
                          ) : (
                            <div className="selected-preview-tile-radio-icon">
                              <div className="selected-preview-tile-radio-icon-check" />
                            </div>
                          )}
                          <div>
                            <div className="preview-pdf-tile-title">{`${
                              pdfType === 'SALES_RECEIPT'
                                ? 'Sales Receipt'
                                : pdfType === 'INVOICE'
                                ? 'Invoice'
                                : 'Credit Memo'
                            } #${invoice}`}</div>
                            {isSigned && (
                              <div className="preview-sign-pdf">
                                <span>Signed</span>
                                {moment(item.date).format('MM/DD LT')}
                              </div>
                            )}
                            {i === 0 && <div className="preview-newest-pdf">New</div>}
                          </div>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
              <div>
                <AppButton
                  text={'Download'}
                  onClick={handleDownload}
                  buttonStyles={{
                    marginTop: '10px',
                    width: '100%',
                  }}
                />
                <AppButton
                  type="SECONDARY"
                  text={'Print'}
                  onClick={print}
                  buttonStyles={{
                    marginTop: '10px',
                    width: '100%',
                  }}
                />
                <AppButton
                  type="TERTIARY"
                  text={'Close'}
                  onClick={handleCloseModal}
                  buttonStyles={{
                    marginTop: '10px',
                    width: '100%',
                  }}
                />
              </div>
            </div>
          )}
          {pdfType === 'RELEASE' && (
            <div>
              {releaseDetails && (
                <div className="release-details-pdf-container">
                  <div className="flex justify-between items-center mb-2">
                    <div className="w-full text-base font-semibold">Release Details</div>
                    {releaseDetails?.transactions && (
                      <div className="w-full grid flex-col justify-items-end" ref={containerRef}>
                        {releaseDetails?.transactions.length === 1 && (
                          <AppButton
                            type="PRIMARY"
                            buttonStyles={{
                              padding: '2px 8px',
                              margin: '0px 0px',
                              borderRadius: '4px',
                            }}
                            text="View Payment"
                            onClick={() => {
                              if (releaseDetails?.transactions) {
                                handleShowPayment(
                                  releaseDetails?.transactions[0]?.id ?? '',
                                  releaseDetails?.transactions[0]?.buyerId ?? ''
                                );
                              }
                            }}
                            iconPosition="RIGHT"
                          />
                        )}
                        {releaseDetails?.transactions.length > 1 && (
                          <div className="custom-dropdown-container  h-fit ">
                            <AppButton
                              type="PRIMARY"
                              buttonStyles={{
                                padding: '2px 8px',
                                margin: '0px 0px',
                                borderRadius: '4px',
                              }}
                              text="View Payment"
                              onClick={handleShowDropdown}
                              iconPosition="RIGHT"
                              icon={<ChevronDown size={18} color={COLORS.WHITE} />}
                            />
                            {showMenu && (
                              <div className="custom-dropdown-menu h-fit">
                                <div className={`flex flex-col py-3 w-56`}>
                                  {releaseDetails.transactions.map((transaction, i) => {
                                    let value;
                                    if (transaction.invoicePayments.length > 1) {
                                      value = `${transaction.invoicePayments[0].invoiceNumber}...${
                                        transaction.invoicePayments[
                                          transaction.invoicePayments.length - 1
                                        ].invoiceNumber
                                      }`;
                                    } else {
                                      value = transaction.invoicePayments[0].invoiceNumber;
                                    }
                                    return (
                                      <button
                                        key={`${transaction.id}_${i}`}
                                        onClick={() =>
                                          handleShowPayment(
                                            transaction.id,
                                            transaction?.buyerId ?? ''
                                          )
                                        }
                                        className="w-full  py-1.5  px-2 font-medium  hover:bg-blue-100 ">
                                        <div>
                                          <div className="flex items-center">
                                            <div className="p-2 PRIMARY_100-BG  mr-2 rounded">
                                              <FileText color={COLORS.PRIMARY_500} size={24} />
                                            </div>
                                            <div className="flex flex-col justify-start justify-items-start items-start">
                                              <div className="text-sm text-black">
                                                {`Invoice #${value}`}
                                              </div>
                                              <div className="text-sm TEXT_SECONDARY-CLR">
                                                Last Update{' '}
                                                <span className="text-black">
                                                  {moment(transaction.updatedAt).format(
                                                    'DD/MM/YYYY'
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {releaseDetails?.transactions &&
                                            releaseDetails.transactions.length - 1 !== i && (
                                              <div className="h-[1px] w-full GREY_300-BG mt-2"></div>
                                            )}
                                        </div>
                                      </button>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="release-details-container">
                    <div className="release-pdf-detail-header">
                      {mode === 'Supplier' ? 'Sender' : 'Recipient'}
                    </div>
                    <div className="release-pdf-detail-text">{releaseDetails.recipient}</div>
                  </div>
                  <div className="release-details-row-container ">
                    <div>
                      <div className="release-pdf-detail-header">Waiver Type</div>
                      <div className="release-pdf-detail-text capitalize">
                        {releaseDetails?.type?.replaceAll('_', ' ')}
                      </div>
                    </div>
                    {releaseDetails.throughDate && (
                      <div>
                        <div className="release-pdf-detail-header">Through Date</div>
                        <div className="release-pdf-detail-text">
                          {moment(releaseDetails.throughDate).format('MM/DD/YYYY')}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="release-details-container">
                    <div className="release-pdf-detail-header">Job</div>
                    <div className="release-pdf-detail-text">{releaseDetails.job}</div>
                  </div>
                  {releaseDetails?.paymentAmount !== null && (
                    <div className="release-details-container">
                      <div className="release-pdf-detail-header">Amount of Payment</div>
                      <div className="release-pdf-detail-text">
                        {formatMoney(releaseDetails?.paymentAmount ?? '', 2)}
                      </div>
                    </div>
                  )}
                  {releaseDetails?.invoiceList && releaseDetails?.invoiceList.length > 0 && (
                    <div className="release-details-container">
                      <p className="release-pdf-detail-header mb-0.5">Invoice List</p>
                      <AppInvoiceList invoiceList={releaseDetails?.invoiceList} />
                    </div>
                  )}
                  {releaseDetails?.notes && (
                    <div className="release-details-container">
                      <p className="release-pdf-detail-header">Notes</p>
                      <p className="release-pdf-detail-text">{`${releaseDetails?.notes ?? ''}`}</p>
                    </div>
                  )}
                  {releaseDetails.dueDate && (
                    <div className="release-details-container">
                      <div className="release-pdf-detail-header">Due Date</div>
                      <div className="release-pdf-detail-text">
                        {moment(releaseDetails.dueDate).format('MM/DD/YYYY')}
                      </div>
                    </div>
                  )}
                  {releaseDetails?.releaseAttachments &&
                    releaseDetails?.releaseAttachments.length > 0 && (
                      <div className="my-1 flex flex-col">
                        <p className="release-pdf-detail-header">{`Attachment${
                          releaseDetails?.releaseAttachments.length > 0 ? 's' : ''
                        }`}</p>
                        {releaseDetails?.releaseAttachments.map((releaseAttachment, i) => (
                          <a
                            key={`${releaseAttachment.name}_${i}`}
                            href={releaseAttachment.url}
                            target="_blank"
                            className="lien-waiver-link">
                            {releaseAttachment.name}
                          </a>
                        ))}
                      </div>
                    )}
                  <div className="flex flex-row">
                    {(releaseDetails?.signedBy || releaseDetails?.signedTime) && (
                      <div className="release-details-container mr-4">
                        <div className="release-pdf-detail-header">Signed By</div>
                        {releaseDetails?.signedBy && (
                          <div className="release-pdf-detail-text">{releaseDetails.signedBy}</div>
                        )}
                        {releaseDetails?.signedTime && (
                          <div className="release-pdf-detail-text">
                            {moment(releaseDetails?.signedTime).format('MM/DD/YYYY  LT')}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {releaseDetails.status === 'Sent to GC' && (
                    <div className="flex flex-row">
                      <div className="release-details-container mr-4">
                        <div className="release-pdf-detail-header">Sent to GC</div>
                        {releaseDetails?.sentToGCEmails && (
                          <div className="release-pdf-detail-text">
                            {releaseDetails.sentToGCEmails.join(', ')}
                          </div>
                        )}
                        {releaseDetails?.sentToGCTime && (
                          <div className="release-pdf-detail-text">
                            {moment(releaseDetails?.sentToGCTime).format('MM/DD/YYYY  LT')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-row">
                    <div className="release-details-container w-32 ">
                      <div className="release-pdf-detail-header">Status</div>
                      <AppReleaseStatusPill
                        status={releaseDetails.status}
                        wrapperClassName="mt-1"
                      />
                    </div>
                  </div>
                  {releaseDetails.status === 'Pending' && showPendingMessage && (
                    <div className="release-pdf-decline-reason-container">
                      <div>
                        <Warning size={24} className="mr-2" color="#93370D" />
                      </div>
                      <div className="release-pdf-decline-reason-text">
                        This release is waiting to be sent. Once the linked payment is successfully
                        processed, this release will get automatically sent.
                      </div>
                    </div>
                  )}
                  <div>
                    {releaseDetails.declineReason && releaseDetails.status === 'Declined' && (
                      <div className="release-details-container">
                        <div className="release-pdf-detail-header">Reason</div>
                        <div className="release-pdf-detail-text">
                          {releaseDetails.declineReason}
                        </div>
                      </div>
                    )}
                    {releaseDetails.declineReason && releaseDetails.status === 'Rejected' && (
                      <div className="release-details-container">
                        <div className="release-pdf-detail-header">Reason</div>
                        <div className="release-pdf-detail-text">
                          {releaseDetails.declineReason}
                        </div>
                      </div>
                    )}
                    {releaseDetails.status === 'Rejected' && (
                      <div className="release-pdf-decline-reason-container">
                        <div>
                          <Warning size={24} className="mr-2" color="#93370D" />
                        </div>
                        <div className="release-pdf-decline-reason-text">
                          {`${
                            mode === 'Buyer'
                              ? 'You’ve rejected your vendor’s lien release signature. They have been notified to re-sign the release'
                              : 'Your lien release signature was rejected for the reason above. Please re-sign according to the feedback'
                          }`}
                        </div>
                      </div>
                    )}
                    {releaseDetails.status === 'Declined' && (
                      <div className="release-pdf-decline-reason-container">
                        <div>
                          <Warning size={24} className="mr-2" color="#93370D" />
                        </div>
                        <div className="release-pdf-decline-reason-text">
                          {mode === 'Buyer'
                            ? 'If declined, you can edit and resend this release request to your supplier'
                            : 'If declined, your contractor may edit and resend this release'}
                        </div>
                      </div>
                    )}
                    {showHistoryButton && (
                      <AppButton
                        type="TERTIARY"
                        text="Show History"
                        buttonStyles={{ padding: '5px', borderRadius: '4px', marginTop: '8px' }}
                        icon={<List color={COLORS.GREY_500} size={20} />}
                        onClick={onShowHistory}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
