import React, { useEffect, useMemo, useState } from 'react';
import { RefreshCcw, Search, Send } from 'react-feather';
import {
  getSupplierSummary,
  getUserCustomers,
  recursiveGetUserCustomers,
  sendEmailStatementsForAllBuyers,
} from '../../../../redux/services/suppliersService';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { CustomersTable } from '../CustomersTable/CustomersTable';
import { useLocation } from 'react-router-dom';
import AppLastSyncDataView from '../../../general/AppLastSyncDataView/AppLastSyncDataView';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Buyer, DocumentsSummary } from '@dill/dill-shared';
import { TotalAmount } from '../TotalAmount/TotalAmount';
import { AppButton } from '../../../general/AppButton/AppButton';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { COLORS } from '../../../../utils/colors';
import moment from 'moment';
import { AppLoadingProgress } from '../../../general/AppLoadingProgress/AppLoadingProgress';

export const Customers = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const { isSupplierSyncing } = useAppSelector((state) => state.generic);
  const { user, firebaseUserId } = useAppSelector((state) => state.auth);
  const mainRoles = user?.roles.filter((role) => role !== 'DILL_ADMIN');
  const [currentCustomers, setCurrentCustomers] = useState<
    {
      buyer: Buyer;
      usersEmailsDetails: {
        email: string;
        isInvitedUser: boolean;
        isStatementsEmailOptOut: boolean;
        isPaymentRemindersEmailOptOut: boolean;
      }[];
      documentsSummary: DocumentsSummary;
    }[]
  >([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [previousLastDocumentId, setPreviousLastDocumentId] = useState('');
  const [customerCount, setCustomerCount] = useState(0);
  const [sortOptions, setSortOptions] = useState<{ sortOrder: 'asc' | 'desc'; sortBy: string }>({
    sortOrder: 'asc',
    sortBy: 'name',
  });

  const searchedCustomers = useMemo(() => {
    if (currentCustomers || searchText) {
      const searched = currentCustomers
        .filter((customerDetails) => {
          if (
            (customerDetails.buyer.name &&
              customerDetails.buyer?.name
                .toString()
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (user?.userSupplier?.integrationType !== 'RUTTER_QBD' &&
              customerDetails.buyer.quickbooksCustomerId &&
              customerDetails.buyer?.quickbooksCustomerId
                .toString()
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (user?.userSupplier?.integrationType !== 'RUTTER_QBD' &&
              customerDetails.buyer.quickbooksCustomerId &&
              customerDetails.buyer.name &&
              `${customerDetails.buyer?.name} - ${customerDetails.buyer?.quickbooksCustomerId}`
                .toString()
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (customerDetails?.buyer.phoneNumbers &&
              customerDetails?.buyer.phoneNumbers.some((phoneNumber) =>
                phoneNumber.toString().toLowerCase().includes(searchText.toLowerCase())
              )) ||
            (customerDetails?.buyer.quickbooksCustomerEmails &&
              customerDetails?.buyer.quickbooksCustomerEmails.some((quickbooksCustomerEmail) =>
                quickbooksCustomerEmail.toString().toLowerCase().includes(searchText.toLowerCase())
              )) ||
            (customerDetails?.buyer?.activities?.lastLoggedIn &&
              moment(customerDetails?.buyer?.activities?.lastLoggedIn)
                .format('MM/DD/YYYY')
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (customerDetails?.buyer?.documentsSummary?.totalAmountDueInvoices &&
              Number(customerDetails?.buyer?.documentsSummary?.totalAmountDueInvoices)
                .toFixed(2)
                .toString()
                .includes(searchText.toLowerCase())) ||
            (customerDetails?.buyer?.documentsSummary?.totalCurrentDueInvoices &&
              Number(customerDetails?.buyer?.documentsSummary?.totalCurrentDueInvoices)
                .toFixed(2)
                .toString()
                .includes(searchText.toLowerCase())) ||
            (customerDetails?.buyer?.documentsSummary?.totalPastDue1To30Invoices &&
              Number(customerDetails?.buyer?.documentsSummary?.totalPastDue1To30Invoices)
                .toFixed(2)
                .toString()
                .includes(searchText.toLowerCase())) ||
            (customerDetails?.buyer?.documentsSummary?.totalPastDue31To60Invoices &&
              Number(customerDetails?.buyer?.documentsSummary?.totalPastDue31To60Invoices)
                .toFixed(2)
                .toString()
                .includes(searchText.toLowerCase())) ||
            (customerDetails?.buyer?.documentsSummary?.totalPastDue61To90Invoices &&
              Number(customerDetails?.buyer?.documentsSummary?.totalPastDue61To90Invoices)
                .toFixed(2)
                .toString()
                .includes(searchText.toLowerCase())) ||
            (customerDetails?.buyer?.documentsSummary?.totalPastDueOver90Invoices &&
              Number(customerDetails?.buyer?.documentsSummary?.totalPastDueOver90Invoices)
                .toFixed(2)
                .toString()
                .includes(searchText.toLowerCase()))
          ) {
            return true;
          }
          return false;
        })
        .sort((customerA, customerB) => {
          const buyerA: any = customerA?.buyer;
          const buyerB: any = customerB?.buyer;
          let sortFieldA = '';
          let sortFieldB = '';
          if (sortOptions.sortBy.includes('documentsSummary')) {
            const splitSortBy = sortOptions.sortBy.split('.');
            console.log({ documentsSummary: buyerA?.documentsSummary });
            if (buyerA && buyerA?.documentsSummary && buyerA?.documentsSummary[splitSortBy[1]]) {
              sortFieldA = buyerA?.documentsSummary[splitSortBy[1]];
            }
            if (buyerB && buyerB?.documentsSummary && buyerB.documentsSummary[splitSortBy[1]]) {
              sortFieldB = buyerB.documentsSummary[splitSortBy[1]];
            }
          } else {
            if (buyerA) {
              sortFieldA = buyerA[sortOptions.sortBy];
            }
            if (buyerB) {
              sortFieldB = buyerB[sortOptions.sortBy];
            }
          }
          if (sortFieldA > sortFieldB) {
            if (sortOptions.sortOrder === 'asc') {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (sortOptions.sortOrder === 'asc') {
              return -1;
            } else {
              return 1;
            }
          }
        });
      return searched;
    }

    return currentCustomers;
  }, [currentCustomers, searchText, sortOptions]);

  useEffect(() => {
    setPreviousLastDocumentId('');
    return () => {};
  }, []);

  const recursiveFetchCustomers = async (
    count: number,
    lastDockId: string,
    firstCustomers: {
      buyer: Buyer;
      usersEmailsDetails: {
        email: string;
        isInvitedUser: boolean;
        isStatementsEmailOptOut: boolean;
        isPaymentRemindersEmailOptOut: boolean;
      }[];
      documentsSummary: DocumentsSummary;
    }[]
  ) => {
    if (currentCustomers.length > count) {
      return;
    }
    setCustomerCount(count);
    const pageSize = 100;
    let currentPreviousLastDocumentId = lastDockId;
    let newCustomers = currentCustomers;
    for (let i = firstCustomers.length; i < count; i += pageSize) {
      const results = await dispatch(
        recursiveGetUserCustomers({
          previousLastDocumentId: currentPreviousLastDocumentId,
          pageSize,
          sortBy: 'name',
          sortOrder: 'asc',
        })
      );
      if (results.type === 'supplier/recursiveGetUserCustomers/fulfilled') {
        if (results.payload) {
          const payload = results.payload as any;
          const fetchedCustomers: {
            buyer: Buyer;
            usersEmailsDetails: {
              email: string;
              isInvitedUser: boolean;
              isStatementsEmailOptOut: boolean;
              isPaymentRemindersEmailOptOut: boolean;
            }[];
            documentsSummary: DocumentsSummary;
          }[] = payload.data as any;
          const lastDocumentId = payload.lastDocumentId;
          if (lastDocumentId !== currentPreviousLastDocumentId) {
            setCurrentCustomers((prevCustomers) => [...prevCustomers, ...fetchedCustomers]);
            setPreviousLastDocumentId(lastDocumentId);
            currentPreviousLastDocumentId = lastDocumentId;
          }
          newCustomers = [...newCustomers, ...fetchedCustomers];
          if (!lastDockId || fetchedCustomers.length < pageSize) {
            console.log('No more pages to fetch');
            break;
          }
        }
      } else {
        console.log('Fetch failed or interrupted');
        break;
      }
    }
  };

  const handleLoadMore = async (loadOptions?: { isFreshFetch?: boolean }) => {
    setLoading(true);
    const pageSize = 100;
    let currentPreviousLastDocumentId = previousLastDocumentId;
    let customersList = currentCustomers;
    if (loadOptions && loadOptions?.isFreshFetch) {
      currentPreviousLastDocumentId = '';
      customersList = [];
    }
    const results = await dispatch(
      getUserCustomers({
        previousLastDocumentId: currentPreviousLastDocumentId,
        pageSize,
        sortBy: 'name',
        sortOrder: 'asc',
      })
    );
    setLoading(false);
    if (results.type === 'supplier/getUserCustomers/fulfilled') {
      if (results.payload) {
        const payload = results.payload as any;
        const cust: {
          buyer: Buyer;
          usersEmailsDetails: {
            email: string;
            isInvitedUser: boolean;
            isStatementsEmailOptOut: boolean;
            isPaymentRemindersEmailOptOut: boolean;
          }[];
          documentsSummary: DocumentsSummary;
        }[] = payload.data as any;
        const lastDockId = payload.lastDocumentId;
        if (!customerCount && payload.customerCount) {
          setCustomerCount(payload.customerCount || 0);
        }
        console.log({ lastDockId, previousLastDocumentId });
        if (lastDockId && lastDockId !== currentPreviousLastDocumentId) {
          setCurrentCustomers([...customersList, ...cust]);
          setPreviousLastDocumentId(lastDockId);

          const count = Number(payload.customerCount);
          recursiveFetchCustomers(count, lastDockId, [...customersList, ...cust]);
        }
      }
    }
  };

  useEffect(() => {
    if (mainRoles?.includes('SUPPLIER') && firebaseUserId) {
      setCurrentCustomers([]);
      setPreviousLastDocumentId('');
      handleLoadMore({ isFreshFetch: true });
    }

    return () => {};
  }, [user?.id, firebaseUserId, location.pathname]);

  useEffect(() => {
    if (mainRoles?.includes('SUPPLIER') && firebaseUserId) {
      dispatch(getSupplierSummary());
    }

    return () => {};
  }, [user?.id, firebaseUserId, location.pathname]);

  const [infiniteRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const handleSendAllStatements = async () => {
    const results = await dispatch(sendEmailStatementsForAllBuyers());
    if (results.type === 'supplier/sendEmailStatementsForAllBuyers/fulfilled') {
      dispatch(
        openMessageModal({
          buttonText: 'Back to Dashbord',
          modalType: 'SUCCESS',
          title: 'Statements emailed!',
          subTitle: `Your statements have been successfully emailed.`,
          onClose: () => {
            handleLoadMore({ isFreshFetch: true });
          },
        })
      );
    } else {
      const payloadError = (results.payload as any) ?? 'Error sending email!';
      dispatch(
        openMessageModal({
          buttonText: 'Back to Dashbord',
          modalType: 'ERROR',
          title: 'Error sending emails!',
          subTitle: `${payloadError}`,
          onClose: () => {
            handleLoadMore({ isFreshFetch: true });
          },
        })
      );
    }
  };

  const handleOpenSendAllStatementsModal = () => {
    dispatch(
      openMessageModal({
        title:
          'Are you sure you want to email statements to all customers with a positive balance?',
        subTitle: 'Statement will not be sent to customers with zero or negative balance.',
        buttonText: 'No, cancel',
        modalType: 'INFO',
        customIcon: <Send size={40} />,
        customIconContainerStyle: {
          padding: '16px',
          backgroundColor: COLORS.PRIMARY_100,
          color: COLORS.PRIMARY_500,
          borderRadius: '50%',
          alignItems: 'center',
        },
        onClose: () => {},
        secondButtonText: 'Yes, email',
        isSecondButton: true,
        onSecondButtonClick: () => {
          handleSendAllStatements();
        },
      })
    );
  };

  return (
    <div className="w-full h-full">
      <div className="flex flex-col mx-10 mt-4  p-3  pb-6 rounded-lg WHITE-BG  h-5/6 relative">
        <div className="absolute right-2 top-2 flex items-center">
          <AppLastSyncDataView dataKey="customers" from="supplier" />
        </div>

        <div className="py-3 flex justify-end">
          <AppInputField
            inputFieldStyles={{ width: '300px' }}
            id="search"
            placeholder={'Search '}
            icon={<Search size={15} />}
            onTextChange={(text) => {
              setSearchText(text);
            }}
          />

          <div className="flex flex-row mt-4 items-center">
            {currentCustomers.length > 0 && (
              <AppLoadingProgress
                text="Customer"
                currentCount={currentCustomers.length}
                totalCount={customerCount}
                showTotalLoading={true}
              />
            )}
            {isSupplierSyncing && <p className="text-xs ml-2">Syncing Data</p>}
            {currentCustomers.length > 0 && (
              <AppButton
                text="Email All Statements"
                onClick={handleOpenSendAllStatementsModal}
                icon={<Send size={18} />}
                buttonStyles={{ marginLeft: '8px' }}
              />
            )}
          </div>
        </div>
        <div className="pb-3" style={{ height: '95%' }}>
          <CustomersTable
            customers={searchedCustomers}
            infiniteRef={infiniteRef}
            hasNextPage={false}
            loading={loading}
            sortOptions={sortOptions}
            onSortChange={({ sortBy, sortOrder }) => {
              setSortOptions({ sortBy, sortOrder });
            }}
            onLoadMore={handleLoadMore}
          />
        </div>
      </div>
    </div>
  );
};
