import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emptyListImg from '../../../../../assets/images/emptyList.png';
import { useAppDispatch } from '../../../../../redux/store';
import { Job, SubSupplierApiResponse } from '@dill/dill-shared';
import { CheckCircle, CircleDashed, Warning } from 'phosphor-react';
import { AppNewJobModal } from '../../../../general/AppNewJobModal/AppNewJobModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AppCustomDropdown from '../../../../general/AppCustomDropdown/AppCustomDropdown';
import { twMerge } from 'tailwind-merge';
import { getJobs, updateJob } from '../../../../../redux/services/jobsService';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import { AppDeleteModal } from '../../../../general/AppDeleteModal/AppDeleteModal';
import successChecksCircle from '../../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import { Edit3 } from 'react-feather';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';

const BuyerLienJobsTable = ({
  jobs,
  subSuppliers,
  selectedJobs,
  onJobsSelected = () => {},
}: {
  jobs: Job[];
  selectedJobs: Job[];
  onJobsSelected?: (jobs: Job[]) => void;
  subSuppliers: SubSupplierApiResponse[];
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedJob, setSelectedJob] = useState({} as Job);
  const [editJob, setEditJob] = useState<boolean>(false);
  const [successMarkAsActive, setSuccessMarkAsActive] = useState<boolean>(false);
  const [errorMarkAsActive, setErrorMarkAsActive] = useState<boolean>(false);
  const [confirmMarkAsActive, setConfiemMarkAsActive] = useState<boolean>(false);
  const [openDropdown, setOpenDropDown] = useState('');
  const [selectedMainBuyerId] = useParamMainBuyerId();

  const columns = useMemo(() => {
    return [
      { id: 'select', name: '', width: 70, cellType: 'SELECT' },
      { id: 'name', name: 'Name', width: 200, cellType: 'VIEW_TEXT' },
      { id: 'number', name: 'Number', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'address', name: 'Address', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'owner', name: 'Owner', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'accountantEmails', name: 'Forwarding Email', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'customerNumber', name: 'Customer Number', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'notes', name: 'Notes', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'status', name: 'Status', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, []);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const handleMarkAsActive = async () => {
    if (!selectedMainBuyerId) {
      return;
    }
    const result = await dispatch(
      updateJob({
        mainBuyerId: selectedMainBuyerId,
        jobId: selectedJob?.id ?? '',
        jobDetails: { archivedState: ['BUYER_ARCHIVED'] },
      })
    );
    if (result.type === 'jobs/updateJob/rejected') {
      setErrorMarkAsActive(true);
    } else {
      setSuccessMarkAsActive(true);
    }
  };

  const selectBuyerJobs = (jobsSelected: Job[]) => {
    const ii = [...selectedJobs.map((iu) => ({ ...iu }))];
    const filteredSupplierJobs = ii.filter(
      (supplierJob) => !jobsSelected.map((yy: any) => yy.id).includes(supplierJob.id)
    );
    onJobsSelected([...filteredSupplierJobs, ...jobsSelected]);
  };

  const unSelectBuyerJobs = (jobsSelected: Job[]) => {
    const filteredSupplierJobs = selectedJobs.filter(
      (supplierJob) => !jobsSelected.map((yy: any) => yy.id).includes(supplierJob.id)
    );
    onJobsSelected([...filteredSupplierJobs]);
  };

  return (
    <div className="w-full h-5/6 flex flex-col border rounded-lg  overflow-y-scroll mt-4">
      <AppNewJobModal
        open={editJob}
        editMode={true}
        selectedJob={selectedJob}
        handleClose={() => setEditJob(false)}
        subSuppliers={subSuppliers}
      />
      <AppDeleteModal
        open={confirmMarkAsActive}
        handleClose={() => setConfiemMarkAsActive(false)}
        title={`Are you sure you want to mark ${selectedJob?.name} as a inactive?`}
        subTitle="This will archive the job and all releases linked to this job. This action can't be undone"
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        onSecondButtonClick={() => {
          handleMarkAsActive();
          setConfiemMarkAsActive(false);
        }}
      />
      <AppSuccessModal
        open={errorMarkAsActive}
        modalImage={errorCircle}
        title={'Failed to mark the job as inactive'}
        subTitle="Contact customer support."
        buttonText="Close"
        handleClose={() => {
          setErrorMarkAsActive(false);
        }}
      />
      <AppSuccessModal
        open={successMarkAsActive}
        modalImage={successChecksCircle}
        title={`Successfully marked job as inactive!`}
        subTitle="This job has been archived and is no longer on the active job list. The linked releases to the job have also been archived"
        buttonText="View All Jobs"
        handleClose={async () => {
          setSuccessMarkAsActive(false);
          if (selectedMainBuyerId) {
            await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
          }
        }}
      />
      <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG  z-10">
        {columns.map((column) => {
          return (
            <div
              key={column.id}
              style={{ minWidth: 50, flex: column.width / totalWidth }}
              className="py-3 px-1">
              <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
            </div>
          );
        })}
      </div>
      {jobs.length === 0 && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col items-center">
            <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
            <p>No jobs</p>
          </div>
        </div>
      )}
      {jobs &&
        jobs.map((job, index) => {
          const status =
            job?.archivedState && job?.archivedState?.includes('BUYER_ARCHIVED')
              ? 'Inactive'
              : 'Active';
          return (
            <div
              key={index + 'oo'}
              onClick={() => navigate(`${job.id}`)}
              className="w-full flex  px-4 items-center justify-between hover:bg-blue-50 cursor-pointer">
              {columns.map((column) => {
                return (
                  <div
                    key={column.id}
                    style={{ minWidth: 50, flex: column.width / totalWidth }}
                    className="py-2 px-1 ">
                    <div className="cursor-pointer flex">
                      {column.cellType === 'SELECT' && column.id === 'select' && (
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          className={`py-3 px-1 `}>
                          <AppCheckBox
                            isChecked={selectedJobs.some((obj) => obj.id === job.id)}
                            onClick={(isChecked: boolean) => {
                              if (isChecked) {
                                selectBuyerJobs([job]);
                              } else {
                                unSelectBuyerJobs([job]);
                              }
                            }}
                          />
                        </div>
                      )}
                      {column.id === 'name' && (
                        <p className="text-sm capitalize overflow-hidden text-ellipsis">
                          {job.name}
                        </p>
                      )}
                      {column.id === 'number' && (
                        <p className="text-sm capitalize truncate">{job.number}</p>
                      )}
                      {column.id === 'address' && (
                        <p className="text-sm capitalize truncate">{job.address}</p>
                      )}
                      {column.id === 'owner' && (
                        <p className="text-sm capitalize truncate">{job.owner}</p>
                      )}
                      {column.id === 'accountantEmails' && (
                        <p className="text-sm truncate">{job.accountantEmails.join(', ')}</p>
                      )}
                      {column.id === 'customerNumber' && (
                        <p className="text-sm  truncate">{job.customerNumber}</p>
                      )}
                      {column.id === 'status' && (
                        <div>
                          <div
                            className={twMerge(
                              'text-xs capitalize font-bold py-1.5 px-3 rounded-full w-fit text-center flex items-center',
                              status === 'Active'
                                ? 'SUCCESS_100-BG SUCCESS_500-CLR'
                                : 'WARNING_100-BG WARNING_500-CLR'
                            )}>
                            {status === 'Active' && <CheckCircle size={15} weight="bold" />}
                            {status === 'Inactive' && <Warning size={15} weight="bold" />}
                            <p className="ml-2"> {status}</p>
                          </div>
                        </div>
                      )}
                      {column.id === 'notes' && (
                        <p
                          className="text-sm whitespace-nowrap  overflow-hidden text-ellipsis"
                          data-tooltip-id={`job-notes-tooltip_${index}`}>
                          {job?.notes}
                        </p>
                      )}
                      <ReactTooltip
                        id={`job-notes-tooltip_${index}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          display: 'flex',
                          background: '#334155',
                          width: '300px',
                          zIndex: '70',
                          borderRadius: '8px',
                          alignItems: 'center',
                          justifyItems: 'center',
                          fontWeight: '600',
                        }}>
                        <span>{job?.notes}</span>
                      </ReactTooltip>
                    </div>
                    {column.id === 'actions' && (
                      <div
                        data-tooltip-id={
                          status === 'Inactive' ? `inactive-job-tooltip_${job.id}` : undefined
                        }
                        className="flex justify-center ">
                        <div>
                          <AppCustomDropdown
                            inputFieldStyles={{ width: '35px', marginLeft: '4px' }}
                            onSelect={(val) => {
                              setSelectedJob(job);
                              if (val === 'edit') {
                                setEditJob(true);
                              }
                              if (val === 'mark-as-inactive') {
                                setConfiemMarkAsActive(true);
                              }
                            }}
                            options={[
                              {
                                value: 'edit',
                                label: 'Edit',
                                icon: <Edit3 size={16} />,
                              },
                              {
                                value: 'mark-as-inactive',
                                label: 'Mark as Inactive',
                                icon: <CircleDashed size={16} />,
                              },
                            ]}
                            width="170px"
                            isDisabled={status === 'Inactive'}
                            open={openDropdown === job.id}
                            onOpen={() => setOpenDropDown(job.id)}
                            onClose={() => setOpenDropDown('')}
                          />
                        </div>
                        <ReactTooltip
                          id={`inactive-job-tooltip_${job.id}`}
                          place="top"
                          variant="dark"
                          opacity={'100%'}
                          style={{
                            display: 'flex',
                            background: '#334155',
                            width: '200px',
                            zIndex: '70',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyItems: 'center',
                            fontWeight: '600',
                          }}>
                          <span>This job has been archived</span>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default BuyerLienJobsTable;
