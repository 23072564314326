import { useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import { X } from 'phosphor-react';

import { SupplierJobApiResponse, SupplierJobUpdate } from '@dill/dill-shared';

import { openDillToast } from '../../../utils/helpers';

import { bulkUpdateSupplierJobs } from '../../../redux/services/supplierJobsService';

import './AppNewSupplierJobModal.scss';

import { useAppDispatch, useAppSelector } from '../../../redux/store';

import { COLORS } from '../../../utils/colors';
import { AppButton } from '../AppButton/AppButton';
import { AppInputField } from '../AppInputField/AppInputField';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';

type PartialSupplierJobUpdatePick = Pick<
  SupplierJobUpdate,
  | 'jobNumber'
  | 'owner'
  | 'ownerAddress'
  | 'ownerCity'
  | 'ownerState'
  | 'ownerZipCode'
  | 'GC'
  | 'GCAddress'
  | 'GCCity'
  | 'GCState'
  | 'GCZipCode'
  | 'buyerId'
  | 'lender'
  | 'lenderAddress'
  | 'lenderCity'
  | 'lenderState'
  | 'lenderZipCode'
  | 'apn'
  | 'customerType'
>;
export const AppBulkEditSupplierJobModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const INITIAL_STATE = {
    jobNumber: '',
    owner: '',
    ownerAddress: '',
    ownerCity: '',
    ownerState: '',
    ownerZipCode: '',
    GC: '',
    GCAddress: '',
    GCCity: '',
    GCState: '',
    GCZipCode: '',
    lender: '',
    lenderAddress: '',
    lenderCity: '',
    lenderState: '',
    lenderZipCode: '',
    apn: '',
  } as PartialSupplierJobUpdatePick;

  const dispatch = useAppDispatch();

  const [supplierJobInput, setSupplierJobInput] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [checkedFields, setCheckedFields] = useState<string[]>([]);

  const { selectedSupplierJobs } = useAppSelector((state) => state.supplierJobs);

  useEffect(() => {
    if (open) {
      setCheckedFields([]);
      setSupplierJobInput({
        jobNumber: '',
        owner: '',
        ownerAddress: '',
        ownerCity: '',
        ownerState: '',
        ownerZipCode: '',
        GC: '',
        GCAddress: '',
        GCCity: '',
        GCState: '',
        GCZipCode: '',
        lender: '',
        lenderAddress: '',
        lenderCity: '',
        lenderState: '',
        lenderZipCode: '',
        apn: '',
      });
      setLoading(false);
    }
  }, [open]);

  const handleCloseModal = () => {
    handleClose();
    setSupplierJobInput(INITIAL_STATE);
    setCheckedFields([]);
    setLoading(false);
  };

  const toggleSelectField = (field: string) => {
    setCheckedFields((prevFields) =>
      prevFields.includes(field)
        ? prevFields.filter((item) => item !== field)
        : [...prevFields, field]
    );
  };

  const handleSubmitJob = async () => {
    setLoading(true);
    let data = {} as PartialSupplierJobUpdatePick;
    checkedFields.forEach((field) => {
      if (field === 'jobNumber') {
        data = {
          ...data,
          jobNumber: supplierJobInput.jobNumber || '',
        };
      }
      if (field === 'owner') {
        data = {
          ...data,
          owner: supplierJobInput.owner || '',
        };
      }
      if (field === 'ownerAddress') {
        data = {
          ...data,
          ownerAddress: supplierJobInput.ownerAddress || '',
          ownerCity: supplierJobInput.ownerCity || '',
          ownerState: supplierJobInput.ownerState || '',
          ownerZipCode: supplierJobInput.ownerZipCode || '',
        };
      }
      if (field === 'gc') {
        data = {
          ...data,
          GC: supplierJobInput.GC || '',
        };
      }
      if (field === 'gcAddress') {
        data = {
          ...data,
          GCAddress: supplierJobInput.GCAddress || '',
          GCCity: supplierJobInput.GCCity || '',
          GCState: supplierJobInput.GCState || '',
          GCZipCode: supplierJobInput.GCZipCode || '',
        };
      }
      if (field === 'lender') {
        data = {
          ...data,
          lender: supplierJobInput.lender || '',
        };
      }
      if (field === 'lenderAddress') {
        data = {
          ...data,
          lenderAddress: supplierJobInput.lenderAddress || '',
          lenderCity: supplierJobInput.lenderCity || '',
          lenderState: supplierJobInput.lenderState || '',
          lenderZipCode: supplierJobInput.lenderZipCode || '',
        };
      }
      if (field === 'apn') {
        data = {
          ...data,
          apn: supplierJobInput.apn || '',
        };
      }
    });

    const bulkUpdatedSupplierJob = await dispatch(
      bulkUpdateSupplierJobs({
        supplierJobId: selectedSupplierJobs.map((job) => job.id).join(','),
        supplierJobDetails: { ...data },
      })
    );
    if (bulkUpdatedSupplierJob.type === 'supplierJobs/bulkUpdateSupplierJobs/fulfilled') {
      const success = bulkUpdatedSupplierJob?.payload?.success ?? true;
      if (success) {
        openDillToast({
          message: `Job${selectedSupplierJobs.length > 1 ? 's' : ''} successfully updated!`,
          type: 'SUCCESS',
        });
      } else {
        const updatedJobs: SupplierJobApiResponse[] = bulkUpdatedSupplierJob?.payload?.data ?? [];
        const failedJobs = selectedSupplierJobs.filter(
          (job) => !updatedJobs.map((updated) => updated.id).includes(job.id)
        );
        openDillToast({
          message: `Failed to update ${
            selectedSupplierJobs.length > 1 ? ' some jobs' : 'this job'
          }: ${failedJobs.map((job) => job.name).join(', ')}`,
          type: 'ERROR',
        });
        setLoading(false);
      }
      handleCloseModal();
    } else {
      openDillToast({
        message: `Failed to update job${selectedSupplierJobs.length > 1 ? 's' : ''}`,
        type: 'ERROR',
      });
      setLoading(false);
    }
  };

  const onTextChange = (key: string, value: string) => {
    setSupplierJobInput((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <>
        <div className={`new-job-modal-content pt-3`}>
          <div className="new-job-modal-title-container">
            <h3 className="font-medium text-lg">{'Bulk Edit Jobs'}</h3>
            <AppButton
              type="FLAT"
              buttonStyles={{ padding: '0px', margin: '5px 2px' }}
              icon={<X color={COLORS.GREY_300} size={30} />}
              onClick={handleClose}
            />
          </div>
          <div className="flex flex-col w-full px-6 py-3">
            <div className="text-sm font-semibold mb-2">Select Fields to Edit</div>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div className="flex items-center mb-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('jobNumber')}
                    onClick={() => {
                      toggleSelectField('jobNumber');
                    }}
                  />
                  <div className="text-sm ml-2">Job Number</div>
                </div>
                <div className="flex items-center mb-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('owner')}
                    onClick={() => {
                      toggleSelectField('owner');
                    }}
                  />
                  <div className="text-sm ml-2">Owner</div>
                </div>
                <div className="flex items-center mb-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('ownerAddress')}
                    onClick={() => {
                      toggleSelectField('ownerAddress');
                    }}
                  />
                  <div className="text-sm ml-2">Owner Address</div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center mb-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('gc')}
                    onClick={() => {
                      toggleSelectField('gc');
                    }}
                  />
                  <div className="text-sm ml-2">GC</div>
                </div>
                <div className="flex items-center mb-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('gcAddress')}
                    onClick={() => {
                      toggleSelectField('gcAddress');
                    }}
                  />
                  <div className="text-sm ml-2">GC Address</div>
                </div>
                <div className="flex items-center mb-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('apn')}
                    onClick={() => {
                      toggleSelectField('apn');
                    }}
                  />
                  <div className="text-sm ml-2">APN</div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center mb-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('lender')}
                    onClick={() => {
                      toggleSelectField('lender');
                    }}
                  />
                  <div className="text-sm ml-2">Lender</div>
                </div>
                <div className="flex items-center mb-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('lenderAddress')}
                    onClick={() => {
                      toggleSelectField('lenderAddress');
                    }}
                  />
                  <div className="text-sm ml-2">Lender Address</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full px-6 py-3 max-h-[60vh] !overflow-y-auto">
            {checkedFields.includes('jobNumber') && (
              <div className="flex flex-col w-full mb-2">
                <div className="w-full">
                  <AppInputField
                    id="jobNumber"
                    label="Job Number"
                    placeholder="Job Number"
                    value={supplierJobInput.jobNumber}
                    onTextChange={(text) => {
                      onTextChange('jobNumber', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('owner') && (
              <div className="flex flex-col w-full mb-2">
                <div className="w-full">
                  <AppInputField
                    id="owner"
                    label="Owner"
                    placeholder="Owner"
                    value={supplierJobInput.owner}
                    onTextChange={(text) => {
                      onTextChange('owner', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('ownerAddress') && (
              <div className="flex mb-2">
                <div className="w-full">
                  <AppInputField
                    id="ownerAddress"
                    label="Owner Address"
                    value={supplierJobInput.ownerAddress}
                    onTextChange={(text) => {
                      onTextChange('ownerAddress', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('ownerAddress') && (
              <div className="flex flex-row justify-around gap-2">
                <div className="w-full">
                  <AppInputField
                    id="ownerCity"
                    label="Owner City"
                    value={supplierJobInput.ownerCity}
                    onTextChange={(text) => {
                      onTextChange('ownerCity', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="ownerState"
                    label="Owner State"
                    value={supplierJobInput.ownerState}
                    onTextChange={(text) => {
                      onTextChange('ownerState', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="ownerZip"
                    label="Owner Zip Code"
                    value={supplierJobInput.ownerZipCode}
                    onTextChange={(text) => {
                      onTextChange('ownerZipCode', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('gc') && (
              <div className="flex flex-row  pb-3">
                <div className="w-full relative">
                  <div className="w-full ">
                    <AppInputField
                      id="gc"
                      label="GC"
                      inputType="text"
                      placeholder="GC"
                      value={supplierJobInput.GC}
                      onTextChange={(text) => {
                        onTextChange('GC', text);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {checkedFields.includes('gcAddress') && (
              <div className="flex flex-row  pb-3">
                <div className="w-full ">
                  <AppInputField
                    id="GCAddress"
                    label="GC Address"
                    inputType="text"
                    placeholder="GC Address"
                    value={supplierJobInput.GCAddress}
                    onTextChange={(text) => {
                      onTextChange('GCAddress', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('gcAddress') && (
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="GCCity"
                    label="GC City"
                    inputType="text"
                    placeholder="San Francisco"
                    value={supplierJobInput.GCCity}
                    onTextChange={(text) => {
                      onTextChange('GCCity', text);
                    }}
                  />
                </div>

                <div className="spacer" />

                <div className="w-full">
                  <AppInputField
                    id="GCState"
                    label="GC State"
                    inputType="text"
                    placeholder="CA"
                    value={supplierJobInput.GCState}
                    onTextChange={(text) => {
                      onTextChange('GCState', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="GCZip"
                    label="GC Zip Code"
                    inputType="text"
                    placeholder="12345"
                    value={supplierJobInput.GCZipCode}
                    onTextChange={(text) => {
                      onTextChange('GCZipCode', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('lender') && (
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="lender"
                    label="Lender"
                    inputType="text"
                    placeholder="Lender"
                    value={supplierJobInput.lender}
                    onTextChange={(text) => {
                      onTextChange('lender', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('lenderAddress') && (
              <div className="flex flex-row  pb-3">
                <div className="w-full ">
                  <AppInputField
                    id="lenderAddress"
                    label="Lender Address"
                    inputType="text"
                    placeholder="Lender Address"
                    value={supplierJobInput.lenderAddress}
                    onTextChange={(text) => {
                      onTextChange('lenderAddress', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('lenderAddress') && (
              <div className="flex flex-row  pb-3">
                <div className="w-full ">
                  <AppInputField
                    id="lenderCity"
                    label="Lender City"
                    inputType="text"
                    placeholder="San Francisco"
                    value={supplierJobInput.lenderCity}
                    onTextChange={(text) => {
                      onTextChange('lenderCity', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="lenderState"
                    label="Lender State"
                    inputType="text"
                    placeholder="CA"
                    value={supplierJobInput.lenderState}
                    onTextChange={(text) => {
                      onTextChange('lenderState', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="lenderZip"
                    label="Lender Zip Code"
                    inputType="text"
                    placeholder="12345"
                    value={supplierJobInput.lenderZipCode}
                    onTextChange={(text) => {
                      onTextChange('lenderZipCode', text);
                    }}
                  />
                </div>
              </div>
            )}
            {checkedFields.includes('apn') && (
              <div className="flex flex-col w-full mt-2">
                <div className="w-full">
                  <AppInputField
                    id="apn"
                    label="APN"
                    placeholder="APN"
                    value={supplierJobInput.apn}
                    onTextChange={(text) => {
                      onTextChange('apn', text);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full mb-3">
            <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
            <div className="flex flex-row self-end px-6">
              <AppButton
                text="Close"
                type="TERTIARY"
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  marginRight: '12px',
                  height: '40px',
                }}
                onClick={handleClose}
              />
              <AppButton
                text={'Bulk Edit Jobs'}
                onClick={() => handleSubmitJob()}
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  height: '40px',
                  width: '120px',
                }}
                isDisabled={loading || checkedFields.length === 0}
                isLoading={loading}
              />
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};
